import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { mixpanelInitialize } from 'utils/mixpanel'
import { Flip, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// components
import { AuthProvider } from './contexts/authContext'
import { PrivateRoute } from './privateRoute'
import { ViewProvider } from './contexts/viewContext'
import { CookiesWidget } from 'features/cookies/cookies'
import { RoutingHistoryProvider } from './contexts/routingHistoryContext'
import { GiBlockchainsDataProvider } from './contexts/GiBlockchainsDataContext'
import { GiDappsDataProvider } from './contexts/giDappsDataContext'
import { View, useHubSpotChatListener, Sidebar } from 'components/lib'
import { paths } from 'utils/paths'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import Cookies from 'js-cookie'
import TagManager from 'react-gtm-module'

// 404
import { NotFound } from 'views/error/404'
import { Forbidden } from 'views/error/403'

// tailwind css
// import '../css/output.css'

// redirects
import { RedirectGrowthIndex } from 'views/redirect/redirectGrowthIndex'
import { RedirectDAppGrowthIndex } from 'views/redirect/redirectDappGrowthIndex'
import { DashboardContentProvider } from './contexts/DashboardContentContext'

// settings
const Settings = require('settings.json')
const StripePromise = loadStripe(
  Settings[process.env.REACT_APP_DEPLOY_ENV].stripe.publishableAPIKey
)

const routes = [
  ...require('routes/setup').default,
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/dashboard').default,
  ...require('routes/project').default,
  ...require('routes/dashboards').default,
  ...require('routes/addDapp').default,
  ...require('routes/builder').default,
  ...require('routes/giEcosystem').default,
  ...require('routes/giDapp').default,
]

export default function App() {
  const [isWidgetOpen, setIsWidgetOpen] = useState(true)
  const cookies = Cookies.get('cookieConsent')
  const user = JSON.parse(localStorage.getItem('user'))
  axios.defaults.baseURL = Settings[process.env.REACT_APP_DEPLOY_ENV].server_url
  const mixpanelProjectToken =
    Settings[process.env.REACT_APP_DEPLOY_ENV].mixpanel.project_token
  const tagManagerArgs = {
    gtmId: 'GTM-5TPQ9TP',
  }

  if (user?.token) {
    // add auth token to api header calls
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
  }

  useEffect(() => {
    if (cookies) {
      const cookiesObject = JSON.parse(cookies)

      if (cookiesObject.analytical) {
        TagManager.initialize(tagManagerArgs)
        mixpanelInitialize(mixpanelProjectToken, {
          track_pageview: false,
          disable_persistence: true,
        })
      }

      if (cookiesObject.marketing) {
        mixpanelInitialize(mixpanelProjectToken, {
          debug: true,
          track_pageview: false,
          persistence: 'localStorage',
        })
      }
    }
  }, [isWidgetOpen])

  useHubSpotChatListener()

  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <AuthProvider>
        <RoutingHistoryProvider>
          <HelmetProvider>
            <GiBlockchainsDataProvider>
              <GiDappsDataProvider>
                <DashboardContentProvider>
                  <BrowserRouter>
                    <ViewProvider>
                      {!cookies && (
                        <CookiesWidget setIsWidgetOpen={setIsWidgetOpen} />
                      )}
                      <Sidebar />
                      <Routes>
                        {routes.map((route) => {
                          return (
                            <Route
                              key={route.path}
                              path={route.path}
                              element={
                                route.permission ? (
                                  <PrivateRoute
                                    permission={route.permission}
                                    route={route}
                                  >
                                    <View
                                      display={route.view}
                                      layout={route.layout}
                                      title={route.title}
                                      access="private"
                                    />
                                  </PrivateRoute>
                                ) : (
                                  <View
                                    access="public"
                                    display={route.view}
                                    layout={route.layout}
                                    title={route.title}
                                  />
                                )
                              }
                            />
                          )
                        })}
                        {/* Redirects */}
                        <Route
                          exact
                          path="/"
                          element={<Navigate to={paths.giEcosystemOverview} />}
                        />
                        <Route path="/basic" element={<RedirectGrowthIndex />} />
                        <Route
                          path="/breakdown"
                          element={<RedirectGrowthIndex />}
                        />
                        <Route
                          path="/growth-index/dapp/basic"
                          element={<RedirectDAppGrowthIndex />}
                        />
                        <Route
                          path="/growth-index/dapp/breakdown"
                          element={<RedirectDAppGrowthIndex />}
                        />
                        <Route
                          path="/growth-index/:type"
                          element={<RedirectGrowthIndex />}
                        />
                        <Route
                          path="/dapps"
                          element={<RedirectDAppGrowthIndex />}
                        />
                        <Route
                          path="/growth-index/dapp"
                          element={<RedirectDAppGrowthIndex />}
                        />

                        {/* 403 */}
                        <Route
                          path="/forbidden"
                          element={
                            <View
                              display={Forbidden}
                              layout="home"
                              title="403 Forbidden"
                            />
                          }
                        />
                        {/* 404 */}
                        <Route
                          path="*"
                          element={
                            <View
                              display={NotFound}
                              layout="home"
                              title="404 Not Found"
                            />
                          }
                        />
                      </Routes>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        limit={4}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick
                        theme="colored"
                        transition={Flip}
                      />
                    </ViewProvider>
                  </BrowserRouter>
                </DashboardContentProvider>
              </GiDappsDataProvider>
            </GiBlockchainsDataProvider>
          </HelmetProvider>
        </RoutingHistoryProvider>
      </AuthProvider>
    </Elements>
  )
}

/***
 *
 *   PUBLIC DASHBOARDS
 *   Page with grid of public dashbboars
 *
 **********/

import React, { useEffect, useState } from 'react'

import {
  Animate,
  useDashboards,
  Grid,
  Typography,
  Loader,
  Container,
} from 'components/lib'
import { paths } from 'utils/paths'

import { Slide } from 'features/dashboardsGrid/components/Slide'

export const PublicDashboardsGrid = () => {
  const [userDashboards, setUserDashboards] = useState()
  const { dashboards, isLoadingDashboards } = useDashboards(`/public`)

  useEffect(() => {
    if (dashboards.length >= 0) {
      setUserDashboards(dashboards)
    }
  }, [dashboards])

  return (
    <Container marginTop16 marginBottom8>
      <Animate type="pop">
        <div className="relative min-height">
          <Grid displayGrid>
            {userDashboards &&
              userDashboards.length > 0 &&
              userDashboards.map(
                ({ title, id, dappName, dappLogo }) => {
                  return (
                    <Slide
                      path={`${paths.dashboard}/${id}`}
                      id={id}
                      title={title}
                      key={id}
                      dappName={dappName}
                      dappLogo={dappLogo}
                    />
                  )
                }
              )}
            {!isLoadingDashboards && userDashboards && userDashboards?.length === 0 && (
              <Typography
                tag="p"
                text="No public dashboards"
                size="s"
              />
            )}
          </Grid>
          {isLoadingDashboards && <Loader />}
        </div>
      </Animate>
    </Container>
  )
}

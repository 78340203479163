/***
 *
 *   GI DAPP BASIC DASHBOARD PAGE
 *
 **********/

import React from 'react'

import {
  Animate,
  MainContentContainer,
} from 'components/lib'

import { GiDappBasicDashboard } from 'features/gi/dapp/giDappBasicDashboard/giDappBasicDashboard'

export function GiDappBasicPage() {

  return (
    <Animate type="pop">
      <MainContentContainer>
        <GiDappBasicDashboard />
      </MainContentContainer>
    </Animate>
  )
}

/***
 *
 *   DAPP USERS
 *
 **********/

import React,{
  useState,
  useEffect,
  useContext
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loader,
  Paginate,
  SmallHeadline,
  ViewContext,
  Container,
  Breadcrumbs,
  useLocation
} from 'components/lib'
import { paths } from 'utils/paths'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

import { fetchGiDappUserInsights } from 'utils/fetches/gi'

import { DappUsersResponseSchema } from 'features/gi/dapp/shared/types/dappUsersResponseSchema'
import { DappUsersFilters } from 'features/gi/dapp/dappUsers/components/dappUsersFilters'
import { useDappUserInsightsFilters } from 'features/gi/dapp/dappUsers/hooks/useDappUserInsightsFilters'
import { DappUsersBasicValues } from 'features/gi/dapp/dappUsers/components/dappUsersBasicValues'
import { ListOfDappUsers } from 'features/gi/dapp/dappUsers/components/listOfDappUsers'
import { DappUserProfile } from 'features/gi/dapp/dappUsers/components/dappUserProfile'
import placeholder from 'features/gi/dapp/dappUsers/assets/icons/placeholder.svg'

export function DappUsers() {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [users, setUsers] = useState()
  const [metrics, setMetrics] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [ , firstSegment ] = location.pathname.split('/')
  const {
    selectedDapp,
    verifiedDappSegment,
    sortByParam,
    sortTypeParam,
    verifiedAddressSegment,
    verifiedPage,
    verifiedPageSize,
    searchAddressParam,
    usedDappsOptions,
    verifiedOtherDappsUsedParam,
    selectedOtherDappsUsed,
    minDepositParam,
    maxDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
    verifiedBlockchainsParam,
    selectedBlockchains
  } = useDappUserInsightsFilters()
  const viewContext = useContext(ViewContext)
  document.title = selectedDapp?.dApp?.name ? `${selectedDapp?.dApp?.name} - Users` : ' dApp Users'
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
    {
      key: 'dapp-growth',
      url: `/${firstSegment}/${paths.giDapp}`,
      name: selectedDapp?.dApp?.name ? `${selectedDapp?.dApp?.name} - Growth` : 'Growth'
    },
    {
      key: 'users',
      url: `/${firstSegment}/users${location.search}`,
      name: 'Users'
    },
  ]

  const handleCurrentPage= (currentPage) => {
    searchParams.delete('page')
    searchParams.append('page', currentPage)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (selectedDapp) {
      const fetchData = async () => {
        try {
          setIsLoadingData(true)
          const bodyRequest = {
            dappSlug: selectedDapp.dApp.slug,
            caller: searchAddressParam,
            otherDappsUsed: verifiedOtherDappsUsedParam ? verifiedOtherDappsUsedParam.split(',') : [
              " "
            ],
            orderType: sortTypeParam?.toUpperCase() || 'DESC',
            orderColumn: sortByParam || 'deposits',
            page: verifiedPage,
            pageSize: verifiedPageSize,
            filters: {
              minDeposit: minDepositParam,
              maxDeposit: maxDepositParam,
              joinedStartDate: joinedStartDate,
              joinedEndDate: joinedEndDate,
              lastInteractionStartDate: lastInteractionStartDateParam,
              lastInteractionEndDate: lastInteractionEndDateParam,
              minInteractions: minInteractionsParam,
              maxInteractions: maxInteractionsParam
            }
          }

          if (verifiedBlockchainsParam) {
            bodyRequest.chains = selectedBlockchains.map(blockchain => blockchain.slug)
          }

          const response = await fetchGiDappUserInsights(bodyRequest)
          const validatedDappUsers = DappUsersResponseSchema.safeParse(response)
          if (!validatedDappUsers.success) {
            logger.error(validatedDappUsers.error)
            throw Error(getValidationErrorMessage('Users'))
          }
          setUsers(validatedDappUsers.data.data)
          setTotalRecords(validatedDappUsers.data.totalRecords)
          setMetrics(validatedDappUsers.data.metrics)
          setIsLoadingData(false)
        } catch (err) {
          setIsLoadingData(false)
          viewContext.handleError(err)
        }
      }
      
      fetchData()
    }
  }, [
    verifiedDappSegment,
    verifiedPageSize,
    sortByParam,
    verifiedPage,
    sortTypeParam,
    searchAddressParam,
    verifiedOtherDappsUsedParam,
    maxDepositParam,
    minDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
    verifiedBlockchainsParam
  ])

  const prepareOptions = (arr) => {
    return (
      arr?.map(item => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.logo && item.logo !== 'not available' ? item.logo : placeholder
          }
        )
      })
    )
  }
  
  return (
    <Container
      noPadding
      className="mb20"
    >
      <Breadcrumbs items={breadcrumbsItems} />
      {verifiedDappSegment ? (
        <>
          <SmallHeadline
            title="Users"
            subtitle={`Top ${totalRecords ? totalRecords : ''} users`}
            className="pt20"
          />
          {selectedOtherDappsUsed.length > 0 || selectedOtherDappsUsed.length === 0 ? (
            <DappUsersFilters
              pageSize={verifiedPageSize}
              searchAddress={searchAddressParam}
              usedDappsOptions={prepareOptions(usedDappsOptions)}
              selectedUsedDapps={prepareOptions(selectedOtherDappsUsed)}
              verifiedOtherDappsUsedParam={verifiedOtherDappsUsedParam}
            />
          ) : (
            null
          )}
          <DappUsersBasicValues
            metrics={metrics}
            isLoadingData={isLoadingData}
          />
          <ListOfDappUsers
            verifiedAddressSegment={verifiedAddressSegment}
            isLoadingData={isLoadingData}
            users={users}
            sortByParam={sortByParam}
            sortTypeParam={sortTypeParam}
            minInteractionsParam={minInteractionsParam}
            maxInteractionsParam={maxInteractionsParam}
            maxDepositParam={maxDepositParam}
            minDepositParam={minDepositParam}
            joinedStartDate={joinedStartDate}
            joinedEndDate={joinedEndDate}
            lastInteractionStartDateParam={lastInteractionStartDateParam}
            lastInteractionEndDateParam={lastInteractionEndDateParam}
            dappSlug={selectedDapp.dApp.slug}
          />
          {!isLoadingData && totalRecords ? (
            <div className="align-center mt24 mb24">
              <Paginate
                totalRecords={totalRecords} 
                limit={verifiedPageSize}
                currentPage={verifiedPage}
                onChange={currentPage => handleCurrentPage(currentPage)}
              />
            </div>
          ) : null}
          {verifiedAddressSegment && (
            <DappUserProfile
              verifiedAddress={verifiedAddressSegment}
              userData={users?.filter(item => item.caller === verifiedAddressSegment)}
              dappSlug={selectedDapp.dApp.slug}
            />
          )}
        </>
        ) : (
          <div className="min-height relative"><Loader /></div> 
      )}
    </Container>
  )
}
/***
 *
 *   useDashboards hook
 *   fetch, format and return dashboards
 *
 **********/

import { useState, useEffect } from 'react'
import { useAPI } from 'components/lib'
import { encodeSVG } from 'utils/helpers'
import { endpoint } from 'utils/paths'
import { fetchImage } from 'utils/fetches/fetches'

import { toast } from 'react-toastify'

import { TDashboard } from 'types/dashboard/dashboard'

import { fetchDashboards } from 'utils/fetches/dashboard'

export function useDashboards(param: string) {
  const [dashboards, setDashboards] = useState<Array<TDashboard>>([])
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingDashboards(true)
        const data = await fetchDashboards(param)
        setDashboards(data)
        setIsLoadingDashboards(false)
      } catch (err) {
        setIsLoadingDashboards(false)
        toast.error('Upss.. There was a problem to fetch dashboards')
      }
    }
      
    fetchData()
  }, [])

  return {
    dashboards,
    isLoadingDashboards,
    setDashboards,
    setIsLoadingDashboards
  }
  // const [state, setState] = useState({ data: null, loading: false })
  // const dashboards = useAPI(`${endpoint.apiDashboards}${param}`)

  // async function prepareDashboardImages(dashboards) {
  //   const preparedDashboards = await Promise.all(
  //     dashboards.data.map(async (dashboard) => {
  //       const image = await prepareImage(dashboard?.looker_id)

  //       return {
  //         image: image,
  //         id: dashboard.id,
  //         title: dashboard.title,
  //         blockchains: dashboard.blockchains,
  //         lookerId: dashboard?.looker_id,
  //         creator: dashboard?.creator,
  //         view_count: dashboard?.view_count,
  //       }
  //     })
  //   )

  //   setState({ data: preparedDashboards, loading: false })
  // }

  // const prepareImage = async (id) => {
  //   const fetchedImage = await fetchImage('dashboard', id)
  //   const convertedImage = encodeSVG(fetchedImage)

  //   return convertedImage
  // }

  // useEffect(() => {
  //   setState({ loading: true })

  //   if (dashboards?.data) {
  //     prepareDashboardImages(dashboards)
  //   }
  // }, [dashboards])

  // return state
}

/***
 *
 *   GI DAPP BREAKDOWN DASHBOARD
 *
 **********/


import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import axios from 'axios'

import {
  ViewContext,
  Loader,
  Breadcrumbs,
  useLocation,
  Typography,
} from 'components/lib'
import {
  endpoint,
  paths
} from 'utils/paths'
import{ Seo } from 'utils/seo'

import { DappBreakdownFilters } from 'features/gi/dapp/giDappBreakdownDashboard/components/dappBreakdownFilters/DappBreakdownFilters'
import { ChartSection } from 'features/gi/dapp/shared/components/chartSection/chartSection'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import {
  USER_ACQUISTION,
  USER_RETENTION,
  USERS_THAT_CHURNED,
  TVL,
  MONETIZATION_FEES,
  INTERACTIONS,
  TG_GROWTH_INDEX
} from 'features/gi/dapp/shared/utils/constans'
import {
  transformToSingleValueFormat,
} from 'features/gi/dapp/giDappBreakdownDashboard/utils/helpers'
import { TopSection } from 'features/gi/dapp/giDappBreakdownDashboard/components/topSection/topSection'
import dappGrowthImg from 'features/gi/dapp/giDappBasicDashboard/assets/images/dapp-growth.png'

export function GiDappBreakdownDashboard() {
  const {
    verifiedDappSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedParamsStr,
    verifiedBlockchainsParam,
    verifiedBlockchainBreakdownParam,
    selectedDappsToCompare,
    selectedDapp,
    selectedBlockchains
  } = useVerifiedGiDappsFiltersParams()
  const location = useLocation()
  const [ , firstSegment ] = location.pathname.split('/')
  document.title = `dApp Growth Breakdown - ${selectedDapp?.dApp?.name || ''}`
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
    {
      key: 'basic',
      url: `/${firstSegment}/${paths.giDapp}`,
      name: selectedDapp?.dApp?.name ? `${selectedDapp?.dApp?.name} - Growth` : 'Growth'
    },
    {
      key: 'breakdown',
      url: `/${firstSegment}/${paths.giDapp}/breakdown${location.search}`,
      name: 'Breakdown'
    },
  ]
  const [chartsData, setChartsData] = useState([
    { name: TG_GROWTH_INDEX, slug: 'tg_growth_index', value: 0 },
    { name: USER_ACQUISTION, slug: 'new_users', value: 0 },
    { name: TVL, slug: 'tvl', value: 0, round: 2, formatValue: true, prefixValue: '$' },
    { name: USER_RETENTION, slug: 'returning_users', value: 0, formatValue: true },
    { name: USERS_THAT_CHURNED, slug: 'churned_users', value: 0, formatValue: true },
    { name: MONETIZATION_FEES, slug: 'fees_usd', value: 0, round: 2, formatValue: true, prefixValue: '$' },
    { name: INTERACTIONS, slug: 'calls', value: 0, formatValue: true },
  ])
  const [timelineDataLoadingStatus, setTimelineDataLoadingStatus] = useState({
    [USER_ACQUISTION]: true,
    [TVL]: false,
    [USER_RETENTION]: false,
    [USERS_THAT_CHURNED]: false,
    [TG_GROWTH_INDEX]: false,
    [INTERACTIONS]: false,
    [MONETIZATION_FEES]: false,
  })
  const [dapp, setDapp] = useState()
  const [comparison, setComparison] = useState()
  const viewContext = useContext(ViewContext)
  const requestControllerTL = {}
  requestControllerTL[USER_RETENTION] = useRef()
  requestControllerTL[USER_ACQUISTION] = useRef()
  requestControllerTL[USERS_THAT_CHURNED] = useRef()
  requestControllerTL[TG_GROWTH_INDEX] = useRef()
  requestControllerTL[TVL] = useRef()
  requestControllerTL[INTERACTIONS] = useRef()
  requestControllerTL[MONETIZATION_FEES] = useRef()

  useEffect(() => {
    if (verifiedDappSegment && verifiedParamsStr) {
      if (verifiedCompareWithParam && selectedDappsToCompare) {
        let comparisonLogo
        let comparisonName
        if (selectedDappsToCompare.length > 1) {
          comparisonLogo = cumulativeIcon
          comparisonName = 'Cumulative'
        } else {
          comparisonLogo = selectedDappsToCompare[0].dApp.icon
          comparisonName = selectedDappsToCompare[0].dApp.name
        }
        setComparison({
          name: comparisonName,
          logo: comparisonLogo
        })
      }

      setDapp({
        name: selectedDapp.dApp.name,
        logo: selectedDapp.dApp.icon,
      })

      const fetchTimeline = async (name, metric) => {
        try {
          setTimelineDataLoadingStatus(prevState => ({
            ...prevState,
            [name]: true,
          }))
          let url = endpoint.apiGiDappTimelineData

          if (requestControllerTL[name].current) {
            requestControllerTL[name].current.abort()
          }
    
          requestControllerTL[name].current = new AbortController()
          const signal = requestControllerTL[name].current.signal
          let compare = []
          if (selectedDappsToCompare && selectedDappsToCompare.length > 0) {
            compare = selectedDappsToCompare.map(item => item.dApp.name)
          }

          const postData = {
            dAppName: selectedDapp?.dApp?.name,
            period: verifiedDateRangeParam,
            metric: metric,
            compareWith: compare,
            blockchainBreakdown: verifiedBlockchainBreakdownParam
          }
          if (verifiedBlockchainsParam) {
            postData.chains = selectedBlockchains.map(blockchain => blockchain.slug)
          }
          
          const response = await axios.post(url, postData, {
            signal: signal
          })
          const singleValueFormatresponse = transformToSingleValueFormat(response.data)
          setChartsData(prevState => prevState.map(obj => {
            if (obj.name === name) {
              return {
                ...obj,
                timelineData: response.data,
                singleValueData: singleValueFormatresponse
              }
            } else {
              return obj
            }
          }))
        
          setTimelineDataLoadingStatus(prevState => ({
            ...prevState,
            [name]: false,
          }))
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setTimelineDataLoadingStatus(prevState => ({
              ...prevState,
              [name]: false,
            }))
            viewContext && viewContext.handleError(err)
          }
        }
      }

      fetchTimeline(TG_GROWTH_INDEX, 'tg_growth_index')
      fetchTimeline(USER_ACQUISTION, 'new_users')
      fetchTimeline(TVL, 'tvl')
      fetchTimeline(USER_RETENTION, 'returning_users')
      fetchTimeline(USERS_THAT_CHURNED, 'churned_users')
      fetchTimeline(INTERACTIONS, 'calls')
      fetchTimeline(MONETIZATION_FEES, 'fees_usd')
    }

  }, [verifiedDappSegment, verifiedParamsStr])

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      {verifiedDappSegment && verifiedParamsStr ? (
        <>
          <TopSection
            tgGrowthIndexTimeline={chartsData[0].timelineData}
            isTgGrowthIndexTimelineLoading={timelineDataLoadingStatus[TG_GROWTH_INDEX]}
            dapp={dapp}
            comparison={comparison}
          />
          <Typography
            text="Filter:"
            tag="h3"
            size="l"
            color="gray900"
          />
          <DappBreakdownFilters />
          {chartsData.slice(1).map(item => {
            return (
              <ChartSection
                key={item.name}
                title={item.name}
                loadingTimelineFlag={timelineDataLoadingStatus[item.name]}
                metric={item.name}
                timelineData={item.timelineData}
                singleValueData={item.singleValueData}
                dapp={dapp}
                comparison={comparison}
                round={item?.round}
                prefixValue={item?.prefixValue}
                formatValue={item?.formatValue}
              />
            )
          })}
        </>
      ) : <div className="min-height relative"><Loader /></div>}
    </>
  )
}

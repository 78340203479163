/***
 *
 *   BASIC VALUES SECTION
 *
 **********/

import React from 'react'

import {
  Button,
  Card,
  useNavigate,
  useLocation,
  Title,
} from 'components/lib'
import { paths } from 'utils/paths'

import { SingleValueGrid } from 'features/gi/dapp/giDappBasicDashboard/components/singleValueGrid/singleValueGrid'
import { BasicValuesContainer } from 'features/gi/dapp/giDappBasicDashboard/components/basicValuesContainer/basicValuesContainer'
import { GaugeSection} from 'features/gi/dapp/shared/components/gaugeSection/gaugeSection'
import { useGiDappBasicValuesData } from 'features/gi/dapp/giDappBasicDashboard/hooks/useGiDappBasicValuesData'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'

export function BasicValuesSection() {
  const navigate = useNavigate()
  const location = useLocation()
  const { giDappBasicValuesData, isGiDappBasicValuesDataLoading } = useGiDappBasicValuesData()
  const { verifiedDappSegment } = useVerifiedGiDappsFiltersParams()

  return (
    <Card darkMode className="mt24">
      <Title small darkMode className="mb16">Last Week Stats</Title>
      <BasicValuesContainer>
        <GaugeSection
          title="dApp Growth Index"
          data={giDappBasicValuesData}
          loadingData={isGiDappBasicValuesDataLoading}
        />
        <SingleValueGrid
          data={giDappBasicValuesData}
          loadingData={isGiDappBasicValuesDataLoading}
        />
        <Button
          fluid
          title="Explore"
          text="Explore"
          darkPrimary
          icon="traditionalArrow"
          iconButton
          iconMediumPosition
          iconColor="primary"
          iconWidth="19"
          iconHeight="13"
          iconPack="own"
          alignRight
          action={() => navigate(`/${verifiedDappSegment}/${paths.giDapp}/breakdown${location.search}`)}
        />
      </BasicValuesContainer>
    </Card>
  )
}

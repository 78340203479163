import { Contact } from 'views/website/contact'
import { Terms } from 'views/website/terms'
import { Privacy } from 'views/website/privacy'
import { TrialExpired } from 'views/website/trialExpired'

const Routes = [
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact',
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy',
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions',
  },
  {
    path: '/trialExpired',
    view: TrialExpired,
    layout: 'home',
    title: 'Your Free Trial has expired',
  },
]

export default Routes

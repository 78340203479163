import { useEffect } from 'react'
import { gtm } from 'utils/analytics/gtm'

export const useHubSpotChatListener = () => {
  useEffect(() => {
    const handleConversationStarted = () => {
      gtm.trackChatStart()
    }

    const setupEventListener = () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.on(
          'conversationStarted',
          handleConversationStarted
        )
      }
    }

    if (window.HubSpotConversations) {
      setupEventListener()
    } else {
      // Set up a one-time event listener for the 'ready' event if HubSpotConversations is not yet loaded
      window.hsConversationsOnReady = window.hsConversationsOnReady || []
      window.hsConversationsOnReady.push(setupEventListener)
    }
  }, [])
}

/***
 *
 *   OVERVIEW
 *   Homepage
 *   List of blockchain with growth index data
 *
 **********/

import React from 'react'

import {
  Animate,
  Breadcrumbs,
  MainContentContainer,
  Container
} from 'components/lib'
import { paths } from 'utils/paths'

import { GiEcosystemOverview } from 'features/gi/ecosystem/giEcosystemOverview/giEcosystemOverview'

export function OverviewPage() {

  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giEcosystemOverview,
      name: 'Ecosystem Ranking',
    },
  ]

  return (
    <MainContentContainer>
      <Animate type="pop">
        <div className="mb24">
          <Breadcrumbs items={breadcrumbsItems}  />
        </div>
        <GiEcosystemOverview />
      </Animate>
    </MainContentContainer>
  )
}
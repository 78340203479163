import { AddDappPage } from 'views/AddDappPage'

const Routes = [
  {
    path: '/add-dapp',
    view: AddDappPage,
    layout: 'builder',
    title: 'Add Dapp',
  },
]

export default Routes

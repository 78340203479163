/***
 *
 *
 *  VERIFY ACCOUNT MODAL CONTENT COMPONENT
 *
 *
 **********/

import React, { useContext, useEffect, useRef } from 'react'
import Axios from 'axios'
import {
  Button,
  ViewContext,
  AuthContext,
  useNavigate,
  IconLib,
  useLocation,
} from 'components/lib'
import { useResendVerificationEmail } from './hooks/useResendVerificationEmail'
import Style from './verifyAccount.module.scss'

export const VerifyAccount = () => {
  const viewContext = useContext(ViewContext)
  const authContext = useRef(useContext(AuthContext))
  const navigate = useNavigate()
  const location = useLocation()
  const qs = location.search
  const resendVerificationEmail = useResendVerificationEmail()

  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        const res = await Axios.post('/api/user/verify', { token: token })
        Axios.defaults.headers.common['Authorization'] =
          'Bearer ' + res.data.token
        authContext.current.update({ verified: true, token: res.data.token })
        viewContext.notification.show(
          `The verification has been successful.`,
          'success',
          true
        )
        return navigate('/')
        // add when plan option will be switch on
        // return navigate(
        //   authContext.current.user.permission === 'user'
        //     ? '/welcome'
        //     : '/signup/plan'
        // )
      } catch (err) {
        // token isnt for this account, force signout
        viewContext.modal.hide(true)
        if (err.response?.status === 401) return authContext.current.signout()
        viewContext && viewContext.handleError(err)
      }
    }

    if (qs.includes('?token=')) {
      // check token exists
      verifyToken(qs.substring(qs.indexOf('?token=') + 7))
    }
  }, [qs, authContext, navigate])

  return (
    <div className={Style.container}>
      <div className={Style.frame}>
        <IconLib image="cursor" size={40} pack="own" />
        <p>We’ve sent a verification link to</p>
        <p>{authContext.current.user.email}</p>
      </div>
      <p className={Style.informationText}>
        Please follow the instructions in your email to complete your account
        verification process and gain access to amazing features of Tokenguard!
      </p>
      <Button
        text="Didn’t receive email? Resend"
        fullWidth
        outline
        outlineDark
        action={() => resendVerificationEmail()}
      />
    </div>
  )
}

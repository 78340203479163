/***
 *
 *   WATERMARK
 *
 **********/

import React from 'react'
import Style from './watermark.module.scss'
import watermark from 'assets/icons/watermark-tg.svg'
import watermarkWhite from 'assets/icons/watermark-white-tg.svg'

export const Watermark = ({ darkMode }) =>  {

  if (darkMode) {
    return <img className={Style.watermark} src={watermarkWhite} alt="tokenguard watermark" />
  }

  return <img className={Style.watermark} src={watermark} alt="tokenguard watermark" />
}

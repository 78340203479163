/***
 *
 *   BOTTOMNAV
 *   bottom navigation in sidebar
 *
 **********/

import React from 'react'
import {
  NavLink,
  matchPath,
} from 'react-router-dom'

import {
  Icon,
  useLocation,
  useMobile,
} from 'components/lib'
import './BottomNav.module.scss'

type TSingleBottomNavItem = {
  link?: string;
  label: string;
  disabled: boolean;
  related: Array<string>;
  icon: string;
  color?: string;
  type?: string;
  action?: () => void;
}

type TBottomNavProps = {
  title: string;
  className: string;
  items: Array<TSingleBottomNavItem>;
  setOpen: (a: boolean) => boolean;
}

export const BottomNav = ({
  title,
  className,
  items,
  setOpen
}: TBottomNavProps) => {
  const isMobile = useMobile()
  const { pathname } = useLocation()

    const verifyLinkClasses = (isDisabled: boolean, relatedArr: Array<string>) => {
      if (isDisabled) {
        return 'relative disabled link-tile'
      }

      if (relatedArr?.length > 0 && relatedArr?.some((path) => matchPath(path, pathname))) {
        return 'active link-tile relative'
      }

      return 'link-tile relative'
    }

  return (
    <>
      {title && <h5 className="nav-app-title">{title}</h5>}
      <nav className={`nav-links ${className}`}>
        {items?.map((item) => {
          if (item.link) {
            return (
              <NavLink
                to={item.link}
                className={verifyLinkClasses(item.disabled, item.related)}
                onClick={() => setOpen(false)}
                key={item.label}
              >
                {({ isActive }) => (
                  <>
                    {item.icon && (
                      <Icon
                        name={item.icon}
                        width={isMobile ? "18" : "16"}
                        height={isMobile ? "18" : "16"}
                        active={item.related?.length > 0 && item.related?.some((path) => matchPath(path, pathname)) || isActive}
                      />
                    )}
                    {item.label && (
                      <span className="label">
                        {item.label}
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            )
          }

          return (
            <div
              className={item.type ? `${item.type} link-tile` : 'link-tile'}
              key={item.label}
              onClick={item.action}
            >
              {item.icon && (
                <Icon
                  name={item.icon}
                  width={isMobile ? "18" : "16"}
                  height={isMobile ? "18" : "16"}
                  color={item.color}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          )
        })}
      </nav>
    </>
  )
}

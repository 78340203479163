import React, { useState } from 'react'

import { 
  Loader,
  Typography,
  Card,
  Breadcrumbs,
} from 'components/lib'
import { useBlockchains } from 'hooks/useBlockchains'
import { TDapp } from 'types/dapp'

import { NoDappContent } from 'features/dapps/shared/components/NoDappContent'
import { useDapps } from 'features/dapps/shared/hooks/useDapps'
import { DappSettingsModal } from 'features/dapps/shared/components/DappSettingsModal'
import { DappTile } from 'features/dapps/gridOfDapps/components/DappTile'
import Style from './GridOfDapps.module.css'

export const GridOfDapps = () =>  {
  const breadcrumbsItems = [
    {
      key: 'dashboard-builder',
      url: '/create',
      name: 'Dashboard Builder',
    }
  ]
  const { dapps, isLoadingDapps } = useDapps()
  const [isDappSettingsModal, setIsDappSettingsModal] = useState<boolean>(false)
  const { blockchains } = useBlockchains(`?dappanalytics=true`)

  return (
    <>
      {isDappSettingsModal && (
        <DappSettingsModal
          setIsDappSettingsModal={setIsDappSettingsModal}
          blockchains={blockchains}
        />
      )}
      <main className={Style['list-of-dapps']}>
        <Breadcrumbs items={breadcrumbsItems} />
        <Card size="full">
          <Typography
            tag="h2"
            text="Select dApp to build dashboard"
            weight="semi-bold"
            size="m"
            color="gray900"
          />
          {!isLoadingDapps && dapps && dapps?.length > 0 ? (
            <GridContainer
              dapps={dapps}
              blockchains={blockchains}
            />
          ) : null}
          {!isLoadingDapps && dapps && dapps?.length === 0 ? (
            <NoDappContent
              setIsDappSettingsModal={setIsDappSettingsModal}
              page="grid"
            />
          ) : null}
          {isLoadingDapps && <Loader />}
        </Card>
      </main>
    </>
  )
}

const GridContainer = ({
  dapps,
  blockchains
}) => (
  <div className={Style['grid-container']}>
    {dapps.map((item: TDapp) => (
      <DappTile
        key={item.id}
        dapp={item}
        blockchains={blockchains}
      />
    ))}
  </div>
)
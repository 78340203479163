import { useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'

const RedirectDAppGrowthIndex = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const dapp = searchParams.get('dapp')

    const queryParams = new URLSearchParams(searchParams)
    if (dapp) {
      queryParams.delete('dapp')
    }

    const restQueryString = queryParams.toString()

    let newPath = ''

    switch (location.pathname) {
      case '/dapps':
        newPath = '/dapp-ranking'
        break
      case '/growth-index/dapp':
        newPath = '/dapp-ranking'
        break
      case '/growth-index/dapp/basic':
        newPath = `/${dapp}/dapp-growth`
        break
      case '/growth-index/dapp/breakdown':
        newPath = `/${dapp}/dapp-growth/breakdown`
        break
      default:
        navigate('/', { replace: true })
        return
    }

    newPath += restQueryString ? `?${restQueryString}` : ''

    const currentPathWithQuery = location.pathname + location.search
    if (newPath !== currentPathWithQuery) {
      navigate(newPath, { replace: true })
    }
  }, [navigate, location, searchParams])

  return null
}

export { RedirectDAppGrowthIndex }

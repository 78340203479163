/***
 *
 *   GROWTH INDEX DAPPS OVERVIEW
 *
 **********/

import React from 'react'

import {
  Loader,
  SmallHeadline,
  Card,
} from 'components/lib'

import { useGiDappsData } from 'features/gi/dapp/shared/hooks/useGiDappsData'
import { DappTable } from 'features/gi/dapp/giDappOverview/components/dappTable'
import { GiBannersContainer } from 'features/gi/shared/components/giBannersContainer/giBannersContainer'
import { GiLegend } from 'features/gi/shared/components/giLegend/giLegend'
import { NewsBanner } from 'features/gi/shared/components/newsBanner/newsBanner'
import { EcosystemBanner } from 'features/gi/dapp/giDappOverview/components/ecosystemBanner'
import { GiDappOverviewFilters } from 'features/gi/dapp/giDappOverview/components/giDappOverviewFilters'

export function GiDappOverview() {
  const { giDappsData, isGiDappsDataLoading } = useGiDappsData()

  return (
    <Card className="mt24">
      <SmallHeadline title="dApp Overview" />
      <GiBannersContainer>
        <GiLegend />
        <EcosystemBanner />
        <NewsBanner />
      </GiBannersContainer>
      {!isGiDappsDataLoading && giDappsData.length > 0 ? (
        <>
          <GiDappOverviewFilters />
          <DappTable data={giDappsData} />
        </>
      ) : null}
      {isGiDappsDataLoading && <div className="min-height relative"><Loader /></div>}
    </Card>
  )
}

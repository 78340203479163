import React from 'react'

import {
  ButtonNew,
} from 'components/lib'
import { Icon } from 'components/icon/Icon'

import Style from './AddDappTopBar.module.scss'

export const AddDappTopBar = ({
  setIsDappSettingsModal,
  setPreviewDapp
}) => (
  <div className={Style['add-dapp-top-bar']}>
    <p className={Style['add-dapp-title']}>Added dApps</p>
    <ButtonNew
      onClick={() => {
        setPreviewDapp()
        setIsDappSettingsModal(true)
      }}
      startIcon={<Icon name="plus" width="16" height="16" />}
    >
      Add dApp
    </ButtonNew>
  </div>
)
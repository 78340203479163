import React from 'react'

import {
  useNavigate,
  IconLib,
  Icon,
  CustomSimpleAreaChart,
  useMobile,
} from 'components/lib'
import {
  handleGrowthIndexLevel,
  capitalizeWords,
} from 'utils/helpers'
import { paths } from 'utils/paths'

import { determineTrend } from 'features/gi/shared/utils/helpers'
import Style from './blockchainTable.module.scss'

export const BlockchainTable = ({ data }) => {
  const navigate = useNavigate()
  const isMobile = useMobile()

  const moveToBasicPage = (slug) => {
    navigate(`/${slug}/${paths.giEcosystem}`)
  }

  const determineRankChange = (change) => {
    if (change === 0) {
      return ''
    } else if (change > 0) {
      return (
        <div className={Style.changeRankContainer}>
          <IconLib
            pack="own"
            iconHeight="10"
            iconWidth="7"
            image="trendUp2"
          />
          <p className={Style.progressRank}>{change}</p>
        </div>
      )
    } else {
      return (
        <div className={Style.changeRankContainer}>
          <IconLib
            pack="own"
            iconHeight="10"
            iconWidth="7"
            image="trendDown2"
          />
          <p className={Style.regressRank}>{Math.abs(change)}</p>
        </div>
      )
    }
  }

  return (
    <div className={Style.tableWrapper}>
      <table className={Style.blockchainTable}>
        <thead>
          <tr className={Style.head}>
            {!isMobile && <th>No</th>}
            <th>
              Blockchain
            </th>
            <th>
              <div className={Style.growthIndexColumnContainer}>
                {!isMobile && (
                  <Icon
                    name="growthIndex"
                    color="gray500"
                    width="16"
                    height="16"
                  />
                )}
                Growth Index
              </div>
            </th>
            <th>
            </th>
            <th>
              <div className={`${Style.growthIndexColumnContainer} ${Style.growthIndexTimelineColumnContainer}`}>
                {!isMobile && (
                    <Icon
                      name="growthIndex"
                      color="gray500"
                      width="16"
                      height="16"
                    />
                )}
                Growth Index Timeline (90d)
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              className={Style.row}
              onClick={() => moveToBasicPage(row.blockchain.slug)}
              key={row.blockchain.id}
            >
              {!isMobile && (
                <td>
                  <div className={Style.tdContainer}>
                    {index + 1}
                    {determineRankChange(row.rankChange)}
                  </div>
                </td>
              )}
              <td>
                <div className={Style.tdContainer}>
                  <img
                    className={Style.logo}
                    src={row.blockchain.logo}
                    alt={`${row.blockchain.name} logo`}
                  />
                  {row.blockchain.name}
                </div>
              </td>
              <td>
                <div className={Style.growthIndexColumnContainer}>
                  <IconLib
                    pack="own"
                    image={`growthIndex${capitalizeWords(handleGrowthIndexLevel(row.growthIndex))}`}
                    size="24"
                  />
                  {row.growthIndex?.toFixed(0) || 0}
                </div>
              </td>
              <td>
              </td>
              <td className={Style.chart}>
                <CustomSimpleAreaChart
                  data={row.data || []}
                  style={{
                    height: "72px",
                    width: "100%",
                    margin: "4px 0 2px 0",
                  }}
                  trend={determineTrend(row.data || [])}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

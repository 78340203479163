/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from 'react'
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  IconLib,
  Link,
  useAPI,
  Loader,
  Container,
} from 'components/lib'

export function Account() {
  const context = useContext(AuthContext) // eslint-disable-line no-unused-vars
  const user = useAPI('/api/user')
  const iconSize = 40

  if (user.loading) return <Loader />

  return (
    <Animate>
      <Container>
        <Grid cols="4">
          <Card>
            <IconLib pack="own" image="profile" size={iconSize} />
            <h2 className={Style.subtitle}>Profile</h2>
            <div>Update your profile information</div>
            <Link url="/account/profile" className={Style.link}>
              Update
            </Link>
          </Card>

          <Card>
            <IconLib pack="own" image="lock" size={iconSize} />
            <h2 className={Style.subtitle}>Password</h2>
            <div>
              {user?.data?.['has_password']
                ? 'Change your password'
                : 'Create a password'}
            </div>
            <Link url="/account/password" className={Style.link}>
              {user?.data?.['has_password'] ? 'Change' : 'Create'}
            </Link>
          </Card>
        </Grid>
      </Container>
    </Animate>
  )
}

const Style = {
  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block',
}

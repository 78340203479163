/***
 *
 *   useVerifiedFiltersParam hook
 * 
 **********/

import _React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAPI } from 'components/lib'

import blockchain from 'assets/icons/blockchains.svg'

export const useDappOverviewFilters = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const [selectedBlockchain, setSelectedBlockchain] = useState()
  const [blockchainsOptions, setBlockchainsOptions] = useState()
  const [verifiedBlockchainParam, setVerifiedBlockchainParam] = useState()
  const giParam = searchParams.get('growth-index')
  const blockchainParam = searchParams.get('blockchain')
  const blockchains = useAPI('api/blockchains?active=true&dappgrowth=true').data

  useEffect(() => {
    if (blockchains) {
      const allBlockchainsOption = {
        label: 'All Blockchains',
        value: 'all',
        icon: blockchain,
        network: 'Mainnet',
        name: 'All Blockchains',
        id: 'all',
      }
      const modifiedBlockchains = blockchains.map(item => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.logo,
            network: item.network || 'Mainnet',
            name: item.name,
            id: item.id,
          }
        )
      })
      setBlockchainsOptions([
        allBlockchainsOption,
        ...modifiedBlockchains,
      ])

      const commonBlockchains = blockchains.filter(blockchain => blockchain.slug === blockchainParam?.toLowerCase())
      if (commonBlockchains.length > 0) {
        setVerifiedBlockchainParam(blockchainParam)
        setSelectedBlockchain(commonBlockchains[0])
      } else {
        setVerifiedBlockchainParam()
        setSelectedBlockchain({ ...allBlockchainsOption, slug: 'all'})
      }
    }

  }, [
    blockchains,
    blockchainParam,
    giParam,
  ])

  return {
    giSortValue: giParam,
    selectedBlockchain: selectedBlockchain,
    verifiedBlockchainParam: verifiedBlockchainParam,
    blockchainsOptions: blockchainsOptions
  }
}
/***
 *
 *   TG BASIC TIMELINE SECTION COMPONENT
 *
 **********/

import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import {
  Card,
  ViewContext,
  Loader,
  CustomAreaChart,
} from 'components/lib'
import { endpoint } from 'utils/paths'

import { SectionTitle } from 'features/gi/ecosystem/shared/components/sectionTitle/sectionTitle'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { CoverChartSection } from 'features/gi/ecosystem/shared/components/coverChartSection/coverChartSection'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'

export function TgGrowthIndexTimeLineSection() {
  const {
    verifiedBlockchainSegment,
    verifiedParamsStr,
    verifiedDateRangeParam,
    verifiedCompareWithParam,
    selectedBlockchainsToCompare,
    selectedBlockchain
  } = useVerifiedFiltersParams()
  const [comparison, setComparison] = useState()
  const [blockchain, setBlockchain] = useState()
  const [tgGrowthIndexTimeline, setTgGrowthIndexTimeline] = useState()
  const [isTgGrowthIndexTimelineLoading, setIsTgGrowthIndexTimelineLoading] = useState(false)
  const viewContext = useContext(ViewContext)
  const requestController = useRef()

  useEffect(() => {
    if (verifiedBlockchainSegment && verifiedParamsStr) {
      let comparisonLogo
      let comparisonName
      if (selectedBlockchainsToCompare && selectedBlockchainsToCompare.length > 1) {
        comparisonLogo = cumulativeIcon
        comparisonName = 'Cumulative'
      }
      if (selectedBlockchainsToCompare && selectedBlockchainsToCompare.length === 1) {
        comparisonLogo = selectedBlockchainsToCompare[0].blockchain.logo
        comparisonName = selectedBlockchainsToCompare[0].blockchain.name
      }
      setComparison({
        name: comparisonName,
        logo: comparisonLogo
      })
      
      setBlockchain({
        name: selectedBlockchain.blockchain.name,
        logo: selectedBlockchain.blockchain.logo,
      })

      const fetchTgGrowthIndexTimeline = async () => {
        try {
          if (requestController.current) {
            requestController.current.abort()
          }
          setIsTgGrowthIndexTimelineLoading(true)

          requestController.current = new AbortController()
          const signal = requestController.current.signal

          let url = endpoint.apiGiBasicTimelineData
          const compare = verifiedCompareWithParam?.split(',') || []
          const postData = {
            chainName: verifiedBlockchainSegment,
            period: verifiedDateRangeParam,
            metric: 'tg_growth_index',
            compareWith: compare,
          }
          
          const growthIndexTimelineResponse = await axios.post(url, postData, {
            signal: signal
          })
          setTgGrowthIndexTimeline(growthIndexTimelineResponse.data)
          setIsTgGrowthIndexTimelineLoading(false)
  
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setIsTgGrowthIndexTimelineLoading(false)
            viewContext && viewContext.handleError(err)
          }
        }
      }

      fetchTgGrowthIndexTimeline()
    }

  }, [verifiedBlockchainSegment, verifiedParamsStr])

  return (
    <Card>
      <SectionTitle title="TG Growth Index Timeline" />
      {isTgGrowthIndexTimelineLoading && <div className="min-height relative"><Loader /></div>}
      {!isTgGrowthIndexTimelineLoading &&
      tgGrowthIndexTimeline?.length > 0 &&
       (
        <CustomAreaChart
          data={tgGrowthIndexTimeline}
          round={0}
          maxValue={100}
        />
      )}
      {!isTgGrowthIndexTimelineLoading &&
      tgGrowthIndexTimeline?.length === 0 && (
        <div className="min-height relative">
          <p className="align-xy">No data</p>
        </div>
      )}
      {!isTgGrowthIndexTimelineLoading && tgGrowthIndexTimeline?.message && <CoverChartSection />}
    </Card>
  )
}

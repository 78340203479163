/***
 *
 *   GROWTH INDEX DAPPS OVERVIEW
 *
 **********/

import React from 'react'

import {
  Loader,
  SmallHeadline,
  Card,
} from 'components/lib'

import { useGiBlockchainsData } from 'features/gi/ecosystem/shared/hooks/useGiBlockchainsData'
import { GiBannersContainer } from 'features/gi/shared/components/giBannersContainer/giBannersContainer'
import { GiLegend } from 'features/gi/shared/components/giLegend/giLegend'
import { DappBanner } from 'features/gi/ecosystem/giEcosystemOverview/components/dappBanner/dappBanner'
import { NewsBanner } from 'features/gi/shared/components/newsBanner/newsBanner'
import { BlockchainTable } from 'features/gi/ecosystem/giEcosystemOverview/components/blockchainTable'

export function GiEcosystemOverview() {
  const { giBlockchainsData, isGiBlockchainsDataLoading } = useGiBlockchainsData()

  return (
    <Card className="mt24">
      <SmallHeadline
        title="Ecosystem Overview"
      />
      <GiBannersContainer>
        <GiLegend />
        <DappBanner />
        <NewsBanner />
      </GiBannersContainer>
      <SmallHeadline
        title="Select Blockchain"
        subtitle="Please select a blockchain to continue"
        className="pb20"
      />
      {!isGiBlockchainsDataLoading && giBlockchainsData.length > 0 ? (
        <BlockchainTable data={giBlockchainsData} />
      ) : null}
      {isGiBlockchainsDataLoading && <div className="min-height relative"><Loader /></div>}
    </Card>
  )
}

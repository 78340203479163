import { DashboardsPage } from 'views/dashboards/dashboards'

const Routes = [
  {
    path: '/dashboards/public',
    view: DashboardsPage,
    layout: 'redesignApp',
    title: 'Public Dashboards',
  },
    {
    path: '/dashboards/',
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Dashboards',
  },
  {
    path: '/dashboards/personal',
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'My Dashboards',
  },
  {
    path: '/dashboards/shared',
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Shared Dashboards',
  },
]

export default Routes

/***
 *
 *   PRIVACY POLICY
 *   Boilerplate privacy statement – replace the values
 *   inside [] with your own text
 *
 **********/

import React from 'react'
import { Article } from 'components/lib'

export function Privacy() {
  return <Article />
}

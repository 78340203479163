/***
 *
 *   DASHBOARD PAGE
 *   specific dashboard view
 *
 **********/

import React from 'react'
import { useParams } from 'react-router-dom'

import {
  Animate,
  Loader,
} from 'components/lib'

import { useDashboard } from 'components/hooks/useDashboard'
import { Dashboard } from 'features/dashboard/Dashboard'

export function DashboardPage() {
  const { id } = useParams()
  const { dashboard, isLoadingDashboard } = useDashboard(id)

  return (
    <Animate type="pop">
      {!isLoadingDashboard &&
      dashboard && (
        <Dashboard dashboardData={dashboard} />
      )}
      {isLoadingDashboard && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </Animate>
  )
}
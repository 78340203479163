import React, { ReactNode } from 'react'
import clsx from 'clsx'

import Style from './ButtonNew.module.css'

interface ButtonProps {
  onClick?: () => void,
  children?: ReactNode,
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large',
  variant?: 'outline' | 'solid',
  color?: string,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  fullWidth?: boolean,
  disabled?: boolean,
  className?: string,
  type?: 'submit'
}

export const ButtonNew: React.FC<ButtonProps> = ({
  onClick,
  children,
  size,
  variant,
  color,
  startIcon,
  endIcon,
  fullWidth,
  disabled,
  className,
  type
}) => {
  const buttonStyle = clsx(
    Style['button'],
    variant ? Style[variant] : Style['solid'],
    size ? Style[size] : Style['medium'],
    color ? Style[color] : Style['primary'],
    fullWidth && Style['fullWidth'],
    disabled && Style['disabled'],
    className && className
  )

  return (
    <button
      className={buttonStyle}
      onClick={onClick}
      type={type}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  )
}
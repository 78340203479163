import React, { useState } from "react"
import Select, { components } from "react-select"
import { Label } from 'components/lib'
import "./reactSelectMultiWithIcon.scss";

const InputOption = ({
  getStyles,
  _Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    display: 'flex',
    backgroundColor: bg,
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  }


  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {rest.data?.icon && <img src={rest.data.icon} alt={rest.data.label} />}
      {children}
    </components.Option>
  )
}

export const ReactSelectMultiWithIcon = ({
  label,
  name,
  placeholder,
  isDisabled,
  defaultValue,
  value,
  change,
  options,
  size,
}) => {

  const customStyles = {
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
      borderRadius: "6px",
      marginTop: "8px"
    }),
  }

  return (
    <div className="react-select-multi-with-icon-container ">
      {label && <Label text={label} size={size} />}

      <Select
        defaultValue={defaultValue}
        value={value}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={customStyles}
        id={name}
        name={name}
        isDisabled={isDisabled}
        placeholder={placeholder}
        classNamePrefix="react-select-multi-with-icon"
        onChange={change}
        options={options}
        components={{
          Option: InputOption
        }}
      />
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import Style from './comparisionBlockchainsTable.module.scss'
import { useMobile } from 'components/lib'

export const ComparisionBlockchainsTable = ({ data }) => {
  const isMobile = useMobile()
  const [sortBy, setSortBy] = useState(null)
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortedData, setSortedData] = useState([])
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))

      const reSort = sortedData.sort((a, b) => {
        const aValue = a.queryData[sortBy]
        const bValue = b.queryData[sortBy]
    
        if (sortOrder === 'asc') {
          return aValue - bValue
        } else {
          return bValue - aValue
        }
      })
      setSortedData(reSort)
    } else {
      setSortBy(column)
      setSortOrder('asc')
      const reSort = sortedData.sort((a, b) => {
        const aValue = a.queryData[column]
        const bValue = b.queryData[column]
        return bValue - aValue
      })
      setSortedData(reSort)
    }
  }

  useEffect(() => {

    if (sortedData.length === 0) {
      const defaultSort = [...data].sort((a, b) => {
        const aValue = a.queryData?.tg_growth_index
        const bValue = b.queryData?.tg_growth_index
  
        if (sortOrder === 'asc') {
          return aValue - bValue
        } else {
          return bValue - aValue
        }
      })
  
      setSortedData(defaultSort)
    }

  }, [])

  return (
    <div className={Style.tableWrapper}>
      <table className={Style.table}>
        <thead>
          <tr className={Style.head}>
            {!isMobile && <th className={Style.noCell}>No</th>}
            <th className={Style.blockchainCell}>Blockchain</th>
            <SortableHeader
              label="TG Growth Index"
              column="tg_growth_index"
              onSort={handleSort}
              sortOrder={sortBy === 'tg_growth_index' ? sortOrder : null}
            />
            <SortableHeader
              label="TVL"
              column="tvl"
              onSort={handleSort}
              sortOrder={sortBy === 'tvl' ? sortOrder : null}
            />
            <SortableHeader
              label="Smart Contracts"
              column="cum_deployed_scs"
              onSort={handleSort}
              sortOrder={sortBy === 'cum_deployed_scs' ? sortOrder : null}
            />
            <SortableHeader
              label="Daily Active Users"
              column="avg_dau"
              onSort={handleSort}
              sortOrder={sortBy === 'avg_dau' ? sortOrder : null}
            />
            <SortableHeader
              label="Transactions"
              column="weekly_txns"
              onSort={handleSort}
              sortOrder={sortBy === 'weekly_txns' ? sortOrder : null}
            />
            <SortableHeader
              label="Fees Paid"
              column="weekly_fees"
              onSort={handleSort}
              sortOrder={sortBy === 'weekly_fees' ? sortOrder : null}
            />
            <SortableHeader
              label="Market Cap"
              column="market_cap"
              onSort={handleSort}
              sortOrder={sortBy === 'market_cap' ? sortOrder : null}
            />
          </tr>
        </thead>
        <tbody>
          {sortedData && sortedData?.map((row, index) => (
            <tr key={row.blockchain.id} className={index % 2 === 0 ? Style.row : `${Style.row} ${Style.oddRow}`}>
              {!isMobile && (
                <td className={Style.noCell}>
                  {index + 1}
                </td>
              )}
              <td className={Style.blockchainCell}>
                <div className={Style.cellContainer}>
                  <img className={Style.logo} src={row.blockchain.logo} alt={`${row.blockchain.name} logo`} />
                  {row.blockchain.name}
                </div>
              </td>
              <td className={Style.tgGrowthIndexCell}>
                {row.queryData.tg_growth_index?.toFixed(0) || 0}
              </td>
              <td>
                ${formatter.format(row.queryData.tvl?.toFixed(0) || 0)}
              </td>
              <td>
                {formatter.format(row.queryData.cum_deployed_scs?.toFixed(0) || 0)}
              </td>
              <td>
                {formatter.format(row.queryData.avg_dau?.toFixed(0) || 0)}
              </td>
              <td>
                {formatter.format(row.queryData.weekly_txns || 0)}
              </td>
              <td>
                ${formatter.format(row.queryData.weekly_fees?.toFixed(0) || 0)}
              </td>
              <td>
                ${formatter.format(row.queryData.market_cap?.toFixed(0) || 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const SortableHeader = ({ label, column, onSort, sortOrder }) => {
  const handleClick = () => {
    onSort(column)
  }

  return (
    <th className={Style.sortableHeader} onClick={handleClick}>
      {label} {sortOrder && <span>{sortOrder === 'asc' ? '▼' : '▲'}</span>}
    </th>
  )
}

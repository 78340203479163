/***
 *
 *   LOGO
 *   logo brand with link to homepage
 *
 **********/

import React from 'react'
import { Link } from 'components/lib'
import brandLogo from 'assets/images/logo-tokenguard.svg'
import brandLogoDark from 'assets/images/logo-tokenguard-dark.svg'
import Style from './logo.module.scss'

export const Logo = ({ dark }) => (
  <Link url="/" className={Style.logo}>
    <img
      src={dark ? brandLogoDark : brandLogo}
      alt="Logo Tokenguard"
    />
  </Link>
)

import React from 'react'
import { ClassHelper } from 'components/lib'
import Style from './gaugeLabel.module.scss'


export const GaugeLabel = ({ icon, darkMode }) => {
  const gaugeLabelStyle = ClassHelper(Style, {
    darkMode: darkMode,
  })

  return (
    <div className={gaugeLabelStyle}>
      <img src={icon} alt="" />
    </div>
  )
}
/***
 *
 *   TERMS & CONDITIONS
 *   Boilerplate terms & conditions – replace the values
 *   inside [] with your own text
 *
 **********/

import React from 'react'
import { Article } from 'components/lib'

export function Terms() {
  return <Article />
}

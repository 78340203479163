/***
 *
 *   Add Dapp Page
 *
 **********/

import React from 'react'

import { AddDapp } from 'features/dapps/addDapp/AddDapp'

export const AddDappPage = () =>  <AddDapp />
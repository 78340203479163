/***
 *
 *   TABBAR
 *   bar element mapping tabs
 *
 *   PROPS
 *   underlyingLine: set bottom border for underline effect
 *
 **********/

import React from 'react'
import { NavLink } from 'react-router-dom'

import { ClassHelper } from 'components/lib'
import Style from './tabBar.module.scss'

export const TabBar = ( props ) => {

  const tabBarStyle = ClassHelper(Style, {
    underlyingLine: props.underlyingLine,
  })

  return (
    <nav className={tabBarStyle}>
      {props.tabs.map((tab, index) => {
        return (
          <NavLink
            key={`${tab.name}${index}`}
            to={tab.path}
            className={({ isActive }) =>
              isActive ? `${Style.activeLink} ${Style.link}` : Style.link
            }
            onClick={tab.onClick}
            end
            disabled
          >
            {tab.name}
          </NavLink>
        )
      })}
    </nav>
  )
}

import axios from 'axios'
import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchDapps = async () => {
  const response = await axios.get(`${serverURL}/api/dapp-analytics/dapp/all`)
  const data = response.data

  return data
}

export const fetchDapp = async (dappId: string) => {
  const response = await axios.get(`${serverURL}/api/dapp-analytics/dapp/${dappId}`)
  const data = response.data

  return data
}

import React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  SmallHeadline,
  // ReactSelectWithIcon,
  CustomSelectNew
  // CustomSelectNew
} from 'components/lib'
import { objectToQueryString } from 'utils/helpers'

import { Sorter } from 'features/gi/dapp/giDappOverview/components/sorter'
import { useDappOverviewFilters } from 'features/gi/dapp/giDappOverview/hooks/useDappOverviewFilters'
import Style from './giDappOverviewFilters.module.scss'

export const GiDappOverviewFilters = () => {
  const [_searchParams, setSearchParams] = useSearchParams()
  const {
    giSortValue,
    selectedBlockchain,
    blockchainsOptions,
    verifiedBlockchainParam,
  } = useDappOverviewFilters()
  const [isFiltersReadyToDisplay, setIsFiltersReadyToDisplay] = useState(false)

  const handleBlockchinFilter = (option) => {
    const queryParams = {
      'blockchain': option.value,
    }
    if (giSortValue) {
      queryParams['growth-index'] = giSortValue
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const sortByGi = (value) => {
    const queryParams = {
      'growth-index': value
    }
    if (verifiedBlockchainParam) {
      queryParams['blockchain'] =  verifiedBlockchainParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  useEffect(() => {
    if (blockchainsOptions && selectedBlockchain) {
      setIsFiltersReadyToDisplay(true) 
    }

  }, [
    selectedBlockchain,
    blockchainsOptions
  ])

  return (
    <>
      <SmallHeadline
        title="Filter:"
      />
      {isFiltersReadyToDisplay && (
        <section className={Style.section}>
          <CustomSelectNew
            options={blockchainsOptions}
            change={handleBlockchinFilter}
            name="blockchain"
            size="medium"
            defaultValue={{
              value: selectedBlockchain.slug,
              label: selectedBlockchain.name,
              icon: selectedBlockchain.logo || selectedBlockchain.icon,
            }}
          />
          <Sorter
            title="Growth Index"
            size="medium"
            action={() => sortByGi(giSortValue === 'desc' ? 'asc' : 'desc')}
            sortOrder={giSortValue || 'random'}
          />
        </section>
      )}
    </>
  )
}

import { DashboardBuilderPage } from 'views/builder/DashboardBuilderPage'
import { GridOfDappsPage } from 'views/builder/GridOfDappsPage'

const Routes = [
  {
    path: '/create',
    view: GridOfDappsPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
  {
    path: '/edit-dashboard/:id',
    view: DashboardBuilderPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
  {
    path: '/create/:dappId',
    view: DashboardBuilderPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
]

export default Routes

/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, Disclaimer } from 'components/lib'
import { SignInContainer } from 'features/auth/signIn'
import { ContentAuthGrid } from 'features/auth/components/contentAuthGrid'
import { InformationAuthColumn } from 'features/auth/components/informationAuthColumn'

export const Signin = () => (
  <Modal title="Log in to Tokenguard" redirect>
    <ContentAuthGrid>
      <div>
        <SignInContainer />
        <Disclaimer>
          New to Tokenguard?{' '}
          <Link to="/signup" text="Sign Up">
            Sign Up
          </Link>
        </Disclaimer>
      </div>
      <InformationAuthColumn
        headline="Log in to access amazing features"
        text="Log in to access amazing features Tokenguard account and access
          features like creating custom dashboards and advanced filters"
      />
    </ContentAuthGrid>
  </Modal>
)

/***
 *
 *   OVERVIEW
 *   Ranking of dapps
 *
 **********/

import React from 'react'

import {
  Animate,
  Breadcrumbs,
  MainContentContainer,
} from 'components/lib'
import { paths } from 'utils/paths'

import { GiDappOverview } from 'features/gi/dapp/giDappOverview/giDappOverview'

export function GiDappOverviewPage() {

  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
  ]

  return (
    <MainContentContainer>
      <Animate type="pop">
        <div className="mb24">
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
        <GiDappOverview />
      </Animate>
    </MainContentContainer>
  )
}

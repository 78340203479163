import { useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'

const RedirectGrowthIndex = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const blockchain = searchParams.get('blockchain')

    if (!blockchain) {
      navigate('/', { replace: true })
      return
    }

    const basePaths = {
      '/basic': 'growth',
      '/breakdown': 'growth/breakdown',
    }

    // Determine the redirection base path either from the URL or the `type` param
    const basePath =
      basePaths[location.pathname] ||
      (location.pathname.includes('growth-index') &&
        (location.pathname.includes('breakdown')
          ? 'growth/breakdown'
          : 'growth'))

    // Redirect URL construction
    const newPath = `/${blockchain}/${basePath}`

    // Redirect to the constructed URL, with or without query string
    navigate(newPath, { replace: true })
  }, [navigate, searchParams, location])

  return null
}

export { RedirectGrowthIndex }

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from 'components/lib'
import dappSymbol from 'assets/images/dapp-symbol.svg'
import { transformObjectBySlug } from 'utils/helpers'

import Style from './DappTile.module.css'

export const DappTile = ({
  dapp,
  blockchains
}) => {
  const navigate = useNavigate()

  return (
    <div
      role="button"
      className={Style['tile']}
      onClick={() => navigate(`/create/${dapp.id}`)}
    >
      <div className={Style['tile-content-container']}>
        <img
          src={dapp.logo || dappSymbol}
          className={Style['dapp-logo']}
          alt={`${dapp.name} logo`}
        />
        <Typography
          tag="p"
          size="m"
          weight="regular"
          color="gray800"
          text={dapp.name}
          className="nowrap"
        />
        <div className={Style['blockchain-label-container']}>
          <img
            className={Style['blockchain-label-icon']}
            alt={`${dapp.name} logo`}
            src={transformObjectBySlug(dapp.blockchain, blockchains).icon}
          />
          <Typography
            tag="p"
            size="s"
            weight="regular"
            text={transformObjectBySlug(dapp.blockchain, blockchains).name}
          />
        </div>
      </div> 
    </div>
  )
}
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import {
  ButtonNew,
  DropdownNew,
  useMobile,
} from 'components/lib'
import { Icon } from 'components/icon/Icon'
import { TDapp } from 'types/dapp'
import { transformObjectBySlug } from 'utils/helpers'

import {
  fetchStartDappIndexing,
  fetchStopDappIndexing
} from 'features/dapps/shared/utils/fetches'

import Style from './TableOfDapps.module.css'

export const TableOfDapps = ({
  dapps,
  blockchains,
  setPreviewDapp,
  setIsDappSettingsModal
}) => {
  const navigate = useNavigate()
  const isMobile = useMobile()
  const baseDappOptions = [
    {
      label: 'Preview',
      action: (dapp: TDapp) => handlePreview(dapp),
      icon: 'preview'
    }
  ]

  const startIndexingOption = {
    label: 'Start Indexing',
    action: (dapp: TDapp) => startIndexingDapp(dapp.id),
    icon: 'start'
  }

  const stopIndexingOption = {
    label: 'Stop Indexing',
    action: (dapp: TDapp) => stopIndexingDapp(dapp.id),
    icon: 'stop'
  }

  const handlePreview = (dapp: TDapp) => {
    setIsDappSettingsModal(true)
    setPreviewDapp(dapp)
  }

  const startIndexingDapp = async (dappId: string) => {
    try {
      await fetchStartDappIndexing({
        id: dappId,
        fromBlock: 0
      })
      window.location.reload()
      
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(`Indexing Error: ${err?.response?.data?.message.toString()}`)
      } else {
        toast.error(err.toString())
      }
    }
  }

  const stopIndexingDapp = async (dappId: string) => {
    try {
      await fetchStopDappIndexing({
        id: dappId,
      })
      window.location.reload()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(`Indexing Error: ${err?.response?.data?.message.toString()}`)
      } else {
        toast.error(err.toString())
      }
    }
  }

	return (
		<table className={Style['table-of-dapps']}>
			<thead>
				<tr>
          {!isMobile && (
			      <th>Nr</th>
          )}
					<th>dApp</th>
					<th>Website</th>
					<th>Blockchain</th>
          <th>Indexed Blocks</th>
					<th>Status</th>
					<th></th>
          <th></th>
				</tr>
			</thead>
			<tbody>
				{dapps.map((item: TDapp, index: string) => (
					<tr key={`${item.name}${index}`}>
            {!isMobile && (
              <td>
                {index + 1}
              </td>
            )}
						<td className={Style['dapp-column-container']}>
              <img
                src={item.logo}
                alt=""
                className={Style['dapp-column-icon']}
              />
							<p>
                {item.name}
              </p>
						</td>
						<td>
							{item.website}
						</td>
						<td>
              {blockchains?.length > 0 ? (
                <div className={Style['blockchain-label-container']}>
                  <img
                    src={transformObjectBySlug(item.blockchain, blockchains).icon}
                    alt="" className={Style['blockchain-label-icon']}
                  />
                  {!isMobile && (
                    <p
                      className={Style['blockchain-label-text']}
                    >
                      {transformObjectBySlug(item.blockchain, blockchains).name}
                    </p>
                  )}
                </div>
              ) : null}
						</td>
            <td>
                {item.indexingStatus || 'n/a'}
						</td>
						<td>
              {showIndexingStatusLabel(item.containerStatus)}
						</td>
            <td>
              <ButtonNew
                onClick={() => navigate(`/create/${item.id}`)}
              >
                Build Dashboard
              </ButtonNew>
						</td>
						<td>
              <div className={Style['settings-container']}>
                <DropdownNew
                  options={[
                    ...baseDappOptions,
                    item.containerStatus === 'Running' ? stopIndexingOption : startIndexingOption
                  ]}
                  title="Settings"
                  id={item}
                  position="bottom-right"
                >
                  <Icon name="more" height="16" width="16" />
                </DropdownNew>
              </div>
            </td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

const showIndexingStatusLabel = (status: string | undefined) => {

  switch(status) {
    case 'Exited':
      return (
        <span className={Style['status-label-stopped']}>
          Stopped
        </span>
      )
    case 'Running':
      return (
        <span className={Style['status-label-running']}>
          Running
        </span>
      )
    case 'not found':
      return (
        <span className={Style['status-label-rest']}>
          Not Found
        </span>
      )
    case undefined:
      return (
        <span className={Style['status-label-rest']}>
          Unrecognized
        </span>
      )
    default:
      return (
        <span className={Style['status-label-rest']}>
          {status}
        </span>
      )
  }
}
/***
 *
 *   Builder dapps list
 *
 **********/

import React from 'react'

import { GridOfDapps } from 'features/dapps/gridOfDapps/GridOfDapps'

export const GridOfDappsPage = () =>  <GridOfDapps />
import React from 'react'

import unknownBlockchain from 'assets/images/unknown-blockchain.svg'
import { TBlockchainOption } from 'types/blockchain'
import { TResult } from 'types/result'

export const capitalizeWords = (sentence: string) => {
  let words = sentence.split(' ')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  return words.join(' ')
}

// trim for sentence to complete word also with cutting to '(' +capitilize
export const trimString = (str: string, length: number) => {
  if (typeof str === 'string') {
    if (str.length > length) {
      let trimmedStr = str.substr(0, length)
      let lastSpaceIndex = trimmedStr.lastIndexOf(' ')
      if (trimmedStr.includes('(')) {
        const lastOpenParenIndex = trimmedStr.lastIndexOf('(')
        if (lastOpenParenIndex < lastSpaceIndex) {
          lastSpaceIndex = lastOpenParenIndex
        }
      }
      trimmedStr =
        trimmedStr.substr(0, Math.min(trimmedStr.length, lastSpaceIndex)) + '...'
      return trimmedStr.charAt(0).toUpperCase() + trimmedStr.slice(1)
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}

// convert format ISO 8601 to - yyyy-mm-dd
export const convertFormatDate = (dateInISO: Date) => {
  let date = new Date(dateInISO)
  let day = date.getDate().toString().padStart(2, '0')
  let month = (date.getMonth() + 1).toString().padStart(2, '0')
  let year = date.getFullYear()

  let formattedDate = `${year}-${month}-${day}`
  
  return formattedDate
}

// for word
export const trimLongString = (str: string, length: number) => {
  if (!/\s/.test(str) && str.length > length) {
    return str.substring(0, length)
  }

  return str
}

export const generateUniqueName = (username: string) => {
  const timestamp = Date.now()
  const uniqueKey = `${username}_${timestamp}`

  return uniqueKey
}

export const encodeSVG = (data) => {
  const blob = new Blob([data], { type: 'image/svg+xml' })
  const imageUrl = URL.createObjectURL(blob)
  const imageElement = <img src={imageUrl} alt="SVG image" />

  return imageElement
}

export const encodePNG = (data) => {
  const blob = new Blob([data], { type: 'image/png' })

  const imageUrl = URL.createObjectURL(blob)
  const imageElement = <img src={imageUrl} alt="png image" />

  return imageElement
}

// example aleph_zero_mainnet =>  aleph zero mainnet
export const transformSnakeCaseToSentence = (string: string) => {
  const result = string.replace(/_/g, ' ')

  return result
}

// example (aleph_zero_mainnet => [Aleph Zero, Mainnet])
export const transformString = (inputString) => {
  const parts = inputString.split('_')
  const result = []

  const firstPart = parts.slice(0, -1).join(' ')
  result.push(firstPart.charAt(0).toUpperCase() + firstPart.slice(1))
  result.push(parts[parts.length - 1])

  return result
}

// function for getting unique value for proper property in array
export const uniqueValues = (arr, propertyTocheck) => {
  const result = [...new Set(arr.map((obj) => obj[propertyTocheck]))]

  return result
}

// ['Mainnet', 'Testnet'] => [{label: Mainnet, value: Mainnet},..]
export const convertToSelectStructure = (arr: Array<string>) => {
  const result = arr.map((val) => ({
    label: val,
    value: val,
  }))

  return result
}

export function toCamelCase(str: string) {
  if (typeof str === 'string') {
    str = str.toLowerCase()
    return str
      .replace(/[-_\s](.)/g, function (match, group1) {
        return group1.toUpperCase()
      })
      .replace(/\s/g, '')
  }
}

export function toKebabCase(str: string) {
  const strWithDashes = str.replace(/\s+/g, '-')
  const result = strWithDashes.toLowerCase()

  return result
}

export const getRandomColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const hue = hash % 360
  return `hsl(${hue}, 65%, 65%)`
}

export function sortUsers(usersData) {
  return [...usersData].sort((a, b) => {
    if (a.tag === 'owner') return -1
    if (b.tag === 'owner') return 1
    if (a.tag === 'waiting') return 1
    if (b.tag === 'waiting') return -1
    return 0
  })
}

export const processEmailList = (emailList: string) => {
  const newEmailArray = emailList.split(',').map((email) => email.trim())
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const validEmails = newEmailArray.filter((email: string) => emailRegex.test(email))
  const invalidEmails = newEmailArray.filter((email: string) => !emailRegex.test(email))

  return { validEmails, invalidEmails }
}

export const objectToQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
  return queryString;
}

export const handleGrowthIndexLevel = (value: number) => {

  if (value <= 20) {
    return 'low'
  } else if (value > 20 && value <= 40) {
    return 'lowMid'
  } else if (value > 40 && value <= 60) {
    return 'mid'
  } else if (value > 60 && value <= 80) {
    return 'midHigh'
  } else {
    return 'high'
  }
}

export function formatToMoney(number: number) {
  return number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).replace(/\.00$/, '')
}

interface StringObj {
  i: string
}

export function generateUniqueString(arr: Array<StringObj>): string {
  let uniqueString: string

  do {
    uniqueString = generateString()
  } while (existsInArray(uniqueString, arr))

  return uniqueString
}

function generateString(): string {

  return Math.random().toString(36).substring(2, 10)
}

function existsInArray(string: string, arr: Array<StringObj>): boolean {

  return arr.some(obj => obj.i === string)
}

export function transformObjectBySlug(slug: string, objectsArray: Array<TBlockchainOption>) {
  const matchedObject = objectsArray.find(obj => obj.slug === slug)

  if (!matchedObject) {

    return (
      {
        name: 'unknown',
        label: 'unknown',
        icon: unknownBlockchain,
        slug: 'unknown',
        value: 'unknown',
      }
    )
  }

  return {
    name: matchedObject.name,
    label: matchedObject.name,
    icon: matchedObject.logo || matchedObject.icon,
    slug: matchedObject.slug,
    value: matchedObject.slug
  }
}

export const convertDataToSingleLineFormat = (data: TResult, metric: string) => {

  if (!metric) {
    return null
  }

  const chartData = data.map(item => {
    return (
      {
        dimension: item.dimension,
        [metric]: item[metric]
      }
    )
  })

  return chartData
}

export const determineChartDataFormat = (data: any) => {
  const keys = Object.keys(data[0])
  if (keys.includes('differential')) {
    const mainMeasure = keys.filter(item => item !== 'dimension' && item !== 'differential')[0]

    const prepareData = (arr: Array<any>, property: string) => {
      let uniqueKeys = new Set()

      arr.forEach(obj => {
        if (property in obj) {
          uniqueKeys.add(obj[property])
        }
      })

      return Array.from(uniqueKeys)
    }

    const preparedDimension = prepareData(data, 'dimension').sort()
    const preparedDifferential = prepareData(data, 'differential')
    let result = []

    preparedDimension.forEach(dimension => {
      let point = {
        dimension: dimension,
      }

      preparedDifferential.forEach((measure: any) => {
        point[measure] = data.filter((item: any) => item.dimension === dimension && item.differential === measure)[0]?.[mainMeasure];
      })

      result.push(point)
    });

    return result
  } else {
    const mainMeasure = keys.filter(item => item !== 'dimension')[0]
    return convertDataToSingleLineFormat(data, mainMeasure)
  }
}

export function removeUselessProperties(filters) {
  if (!Array.isArray(filters)) {
    return filters
  }

  return filters.map(filter => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filterOptions, _id, ...rest } = filter

    return rest
  })
}

export const verifyDataForSingleValue = (data: unknown, metric: string) => {
  if (typeof data === 'number' || typeof data === 'string') {
    return true
  }

  if (Array.isArray(data) && data?.length > 0) {
    const keys = Object.keys(data[0])
    const measures = keys.filter(item => item !== 'dimension')

    if (measures.length === 1) {
      return true
    }

    if (metric && measures.includes(metric)) {
      return true
    }
  }

  if (typeof data === 'object') {
    const keys = Object.keys(data)
    const measures = keys.filter(item => item !== 'dimension')

    if (measures.length === 1) {      
      return true
    }

    if (metric && measures.includes(metric)) {
      return true
    }
  }

  return false
}

export const adjustForSingleValue = (data: unknown, defaultValue: string | number, metric: string) => {
  if (typeof data === 'number' || typeof data === 'string') {
    return { currentValue: data }
  }

  if (Array.isArray(data) && data?.length > 0) {
    const keys = Object.keys(data[0])
    if (!keys.includes('differential')) {
      const measures = keys.filter(item => item !== 'dimension')
      if (measures.length === 1) {
        const property = measures[0]
        const lastIndex = data.length - 1
        const result = data[lastIndex][property]
  
        return { currentValue: result }
      }
  
      if (metric && measures.includes(metric)) {
        const property = measures.filter(item => item === metric)
        const lastIndex = data.length - 1
        const result = data[lastIndex][property[0]]
  
        return { currentValue: result }
      }
    }
  }

  if (typeof data === 'object') {
    const keys = Object.keys(data)
    const measures = keys.filter(item => item !== 'dimension')

    if (measures.length === 1) {
      const property = measures[0]
      const result = data[property]
      
      return { currentValue: result }
    }

    if (metric && measures.includes(metric)) {
      const property = measures.filter(item => item === metric)
      const result = data[property[0]]

      return { currentValue: result }
    }
  }

  return defaultValue
}

export function shortenAddress(string: string) {
  const length = string.length
  if (length <= 10) {

    return string
  } else {
    const firstPart = string.slice(0, 6)
    const lastPart = string.slice(-4)
    
    return firstPart + "..." + lastPart
  }
}

export const getValidationErrorMessage = (data: string) => {
  return (
    `We apologize, but we're having trouble displaying ${data} data right now. If the problem persists,
    please contact our support team for further assistance. Thank you for your patience!`
  )
}

// calculate height of element in px by passing height as a param (get from dashboard layout element)
export function calcHeight(height: number) {
  const fullHeightOfCard = height * 2 * 10
  const result = fullHeightOfCard - 48 - 18 - 24

  return `${result}px`
}
/***
 *
 *   INFORMATION AUTH COLUMN
 *   column for image and some description
 *
 **********/

import React from 'react'
import Style from './informationAuthColumn.module.scss'
import tokenguardAppPreview from 'assets/images/tokenguard-app-preview.png'

export const InformationAuthColumn = ({ headline, text }) => (
  <div className={Style.column}>
    <img src={tokenguardAppPreview} alt="tokenguard app preview" />
    <p className={Style.headline}>{headline}</p>
    <p className={Style.text}>{text}</p>
  </div>
)

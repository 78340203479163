import React from 'react'
import { Tooltip } from 'react-tooltip'

import { ClassHelper } from 'components/lib'
import 'react-tooltip/dist/react-tooltip.css'
import Style from './reactTooltip.module.scss'

export const ReactTooltip = ({ id, withArrow, light, small }) => {
  const tooltipStyle = ClassHelper(Style, {
    light: light,
    small: small,
  })

  return (
    <Tooltip
      id={id}
      className={tooltipStyle}
      classNameArrow={withArrow ? Style.arrow : Style.noArrow}
    />
  )
}

/***
 *
 *   GI DAPP BASIC DASHBOARD COMPONENT
 *
 **********/

import React from 'react'

import {
  Loader,
  Breadcrumbs,
  useLocation,
} from 'components/lib'
import { Seo } from 'utils/seo'
import { paths } from 'utils/paths'

import { BasicValuesSection } from 'features/gi/dapp/giDappBasicDashboard/components/basicValuesSection/basicValuesSection'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import { GiDappAIInsights } from 'features/gi/dapp/giDappBasicDashboard/components/giDappAIInsights/giDappAIInsights'
import { DappSankeySection } from 'features/gi/dapp/giDappBasicDashboard/components/dappSankeySection/dappSankeySection'
import { TopUsers } from 'features/gi/dapp/giDappBasicDashboard/components/topUsers/topUsers'
import { DappBasicFilters } from 'features/gi/dapp/giDappBasicDashboard/components/dappBasicFilters/DappBasicFilters'
import dappGrowthImg from 'features/gi/dapp/giDappBasicDashboard/assets/images/dapp-growth.png'

export function GiDappBasicDashboard() {
  const {
    selectedDapp,
    verifiedDappSegment,
    verifiedParamsStr,
    verifiedBlockchainsParam,
    selectedBlockchains,
    selectedDappsToCompare,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedBlockchainBreakdownParam,
  } = useVerifiedGiDappsFiltersParams()
  const location = useLocation()
  const [ , firstSegment ] = location.pathname.split('/')
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
    {
      key: 'dapp-growth',
      url: `/${firstSegment}/${paths.giDapp}${location.search}`,
      name: selectedDapp?.dApp?.name ? `${selectedDapp?.dApp?.name} - Growth` : 'Growth'
    },
  ]

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      <Seo
        title={`dApp Growth - ${selectedDapp?.dApp?.name || ''}`}
        description="Latest on-chain growth insights on protocol usage and user behavior"
        imageUrl={dappGrowthImg}
      />
      {verifiedDappSegment && verifiedParamsStr ? (
        <>
          <BasicValuesSection />
          <DappBasicFilters
            selectedDapp={selectedDapp}
            selectedDappsToCompare={selectedDappsToCompare}
            verifiedDappSegment={verifiedDappSegment}
            verifiedCompareWithParam={verifiedCompareWithParam}
            verifiedDateRangeParam={verifiedDateRangeParam}
            verifiedBlockchainBreakdownParam={verifiedBlockchainBreakdownParam}
            verifiedBlockchainsParam={verifiedBlockchainsParam}
            selectedBlockchains={selectedBlockchains}
          />
          <GiDappAIInsights
            dappName={selectedDapp?.dApp?.name}
            title="Last Week Insights"
          />
          {selectedBlockchains.length > 0 || selectedBlockchains.length === 0 ? (
            <>
              <DappSankeySection
                dappSlug={selectedDapp?.dApp?.slug}
                blockchains={selectedBlockchains}
              />
              <TopUsers
                dappSlug={selectedDapp?.dApp?.slug}
                blockchains={selectedBlockchains}
              />
            </>
          ): null}
        </>
        ) : (
          <div className="min-height relative"><Loader /></div>
      )}
    </>
  )
}
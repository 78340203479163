/***
 * HOOK for set nav  bottom items
 *
 **********/

import { useContext } from 'react'
import { AuthContext, useNavigate } from 'components/lib'

export function useNavBottomItems() {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  const navBottomItems = authContext.user?.token
    ? [
        {
          label: authContext.user.name,
          icon: 'user',
          link: '/account',
          related: [],
          disabled: false,
        },
        {
          label: 'Help Center',
          icon: 'help',
          link: '/help',
          related: [],
          disabled: false,
        },
        {
          label: 'Sign Out',
          icon: 'signOut',
          action: authContext.signout,
          color: 'danger500',
        },
      ]
    : [
        {
          label: 'Sign Up',
          icon: 'signUp',
          action: () => navigate('/signup'),
        },
        {
          label: 'Log In',
          icon: 'signIn',
          action: () => navigate('/signin'),
        },
      ]

  return navBottomItems
}

/***
 * 
 * HOOK of creator tools nav items
 *
 **********/

export function useNavAnalyticsItems() {

  const navCommunityItems = [
    {
      label: 'Add dApp',
      icon: 'plus',
      link: '/add-dapp',
      disabled: false,
    },
  ]

  return navCommunityItems
}

import React from 'react'

import { AnimationValue } from 'components/lib'

import Style from './insightSingleValue.module.scss'

export const InsightSingleValue = ({
  title,
  value,
  prefix
}) => {

  return (
    <div className={Style.container}>
      <p className={Style.value}>
        <AnimationValue value={value} prefix={prefix} />
      </p>
      <p className={Style.title}>{title}</p>
    </div>
  )
}
import React from 'react'
import { ButtonNew, TextInput } from 'components/lib'
import Style from './shareLink.module.scss'

export const ShareLink = ({ accessLink, contentType, isId, lookerId }) => {
  const currentUrl = window.location.origin
  const urlPath = isId
    ? `${currentUrl}/${contentType}/${lookerId || accessLink}`
    : `${currentUrl}/${contentType}/share/${accessLink}`

  return (
    <div className={Style.shareLinkContainer}>
      <TextInput
        label="Link"
        disabled={!accessLink}
        value={accessLink && urlPath}
      />
      <ButtonNew
        onClick={() => {
          navigator.clipboard.writeText(urlPath)
        }}
        disabled={!accessLink}
        size="medium"
        variant="outline"
      >
        Copy
      </ButtonNew>
    </div>
  )
}

import React from 'react'
import { ButtonNew, TextInput } from 'components/lib'
import Style from './inviteEmails.module.scss'

export const InviteEmails = ({
  emailList,
  handleEmailListChange,
  handleInviteClick,
  validInput,
}) => (
  <div className={Style.inviteEmailsContainer}>
    <TextInput
      label="Invite people"
      value={emailList}
      onChange={handleEmailListChange}
      placeholder="Email, comma separated"
      valid={validInput}
      errorMessage="Please enter a valid email address"
    />
    <ButtonNew
      size="medium"
      variant="outline"
      className={Style.inviteButton}
      onClick={handleInviteClick}
      disabled={!emailList}
    >
      Invite
    </ButtonNew>
  </div>
)

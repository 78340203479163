import React from 'react'
import Style from './trendLabel.module.scss'
import { IconLib, ClassHelper } from 'components/lib'

export const TrendLabel = ({
  trend,
  darkMode,
  variant
}) => {
  const trendLabelStyle = ClassHelper(Style, {
    darkMode: darkMode,
  })
  const formattedTrendValue = trend.toFixed(0)
  
  if (Math.abs(Number(formattedTrendValue)) === 0) {
    return (
      <div className={trendLabelStyle}>
        <div className={Style.trendLabelValue}>
          <p>{Math.abs(Number(formattedTrendValue))}%</p>
        </div>
      </div>
    )
  }

  return (
    <div className={trendLabelStyle}>
      <div className={Style.trendLabelValue}>
        <p>{Number(formattedTrendValue) > 0 ? `+${formattedTrendValue}%` : `${formattedTrendValue}%`}</p>
        {variant === 'comparison' ? (
          <IconLib pack="own" iconHeight="11" iconWidth="7" image={formattedTrendValue > 0 ? "trendUp2" : "trendDown2"} />
        ) : (
          <IconLib pack="own" iconHeight="14" iconWidth="14" image={formattedTrendValue > 0 ? "trendUp" : "trendDown"} />
        )}
      </div>
    </div>
  )
}

import React from 'react'
import Style from './sectionTitle.module.scss'
import { ClassHelper } from 'components/lib'

export const SectionTitle = (props) => {
  const titleStyle = ClassHelper(Style, props)

  return (
    <p className={titleStyle}>{props.title}</p>
  )
}

import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import {
  BarChart,
  GaugeChart,
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from 'echarts/components'
import {
  CanvasRenderer,
} from 'echarts/renderers'
import { palette } from 'utils/constans'

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, GaugeChart]
)

export const CustomGauge = ({
  value,
  level,
  thickness,
  darkMode
}) => {
  let itemColor
  let bgItemColor = palette.gray200
  let itemSize
  let detailFontSize
  let detailLineHeight
  let detailOffsetCenter
  let fontColor = palette.gray900

  if (darkMode) {
    fontColor = palette.white
    bgItemColor = new echarts.graphic.LinearGradient(0, 1, 1, 0, [
      // {
      //   offset: 1,
      //   color: "#3B687E",
      // },
      // {
      //   offset: 0,
      //   color: "#093C56",
      // },
      {
        offset: 1,
        color: "#010D12",
      },
      {
        offset: 0,
        color: "#06293B",
      },
    ])
  }

  if (level === 'low') {
    itemColor = palette.danger
  } else if (level === 'lowMid') {
    itemColor = palette.warning
  } else if (level === 'mid') {
    itemColor = palette.warning500
  } else if (level === 'midHigh') {
    itemColor = palette.blue
  } else {
    itemColor = palette.primary
  }

  if (thickness === 'small') {
    itemSize = 15
    detailFontSize = 24
    detailLineHeight = 32
    detailOffsetCenter = [0, 0]
  } else {
    itemSize = 25
  }

  const option = {
    series: [
      {
        type: 'gauge',
        center: ['50%', '60%'],
        startAngle: 200,
        endAngle: -20,
        splitNumber: 10,
        splitLine: {
          show: false,
        },
        itemStyle: {
          color: itemColor,
        },
        progress: {
          roundCap: true,
          show: true,
          width: itemSize
        },
        pointer: {
          show: false
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: itemSize,
            color: [
              [1, bgItemColor]
            ]
          }
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false
        },
        anchor: {
          show: false
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: true,
          width: '60%',
          lineHeight: detailLineHeight || 40,
          borderRadius: 8,
          offsetCenter: detailOffsetCenter || [0, '-15%'],
          fontSize: detailFontSize || 32,
          formatter: '{value}',
          color: fontColor,
        },
        data: [
          {
            value: value
          }
        ]
      },
    ]
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{width: '100%'}}
    />
  )
}

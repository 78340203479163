import React from 'react'

import { Icon } from 'components/lib'
import { paths } from 'utils/paths'

import Style from './dappBanner.module.scss'

export const DappBanner = () => (
  <a href={paths.giDappOverview} className={Style.dappsBannerContainer}>
    <div className={Style.dappsBannerContent}>
      <Icon name="dapp" width="16" height="16" />
      <p className={Style.dappsBannerTitle}>dApp Ranking</p>
    </div>
  </a>
)

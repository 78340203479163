import React from 'react'

import {
  Breadcrumbs
} from 'components/lib'

import Style from './TopBar.module.css'

export const TopBar = () => {
  const breadcrumbsItems = [
    {
      key: 'create',
      url: '/create',
      name: 'dApp Analytics',
    }
  ]

  return (
    <div className={Style['topbar']}>
      <Breadcrumbs items={breadcrumbsItems} />
    </div>
  )
}
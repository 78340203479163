/***
 *
 *   ICON
 *   Render an icon from feather icons
 *
 *   PROPS
 *   color: dark/light/grey/green/orange/blue/primary or hex code
 *   pack: icon pack to use, default is feathericons
 *   image: image to use (see: https://feathericons.com) / fontawesome.com
 *   className: inject a parent class object
 *
 **********/

import React from 'react'
import FeatherIcon from 'feather-icons-react'
import iconUrl from 'assets/icons/sprite.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { palette } from 'utils/constans'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faShield } from '@fortawesome/free-solid-svg-icons'

library.add(fab)

const iconMap = {
  faChartLine,
  faChartColumn,
  faPlus,
  faUsers,
  faUser,
  faTrophy, //nomination
  faArrowsLeftRight, // transfers
  faLayerGroup, //staking
  faClipboard, // smart contracts
  faQuestion,
  faUserPlus,
  faStar,
  faShield,
}

export function IconLib(props) {
  const packs = {
    fontawesome: FontAwesomeIcon,
    feather: FeatherIcon,
  }

  const Icon = packs[props.pack || 'feather']
  const fill = palette[props.color] || palette.light
  const icon =
    props.pack === 'fontawesome'
      ? iconMap[props.image] || props.image
      : props.image

  return (
    <>
      {props.pack !== 'own' ? (
        <Icon
          color={props.active ? palette.primary : fill}
          icon={icon}
          size={props.size || 16}
          className={props.className}
        />
      ) : (
        <svg
          className={props.className ? `${props.className} icon` : 'icon'}
          width={props.size || props.iconWidth || 16}
          height={props.size || props.iconHeight || 16}
          fill={props.active ? palette.primary : fill}
        >
          <use href={`${iconUrl}#${icon}`} />
        </svg>
      )}
    </>
  )
}

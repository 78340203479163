import React from 'react'
import ReactDOMServer from 'react-dom/server'

import {
  CustomGauge,
  ReactTooltip,
  IconLib,
  Loader,
  useMobile,
  Typography,
} from 'components/lib'
import { handleGrowthIndexLevel } from 'utils/helpers'
import informations from 'utils/informations.json'

import { GaugeLegend } from 'features/gi/ecosystem/shared/components/gaugeLegend/gaugeLegend'
import { SectionTitle } from 'features/gi/ecosystem/shared/components/sectionTitle/sectionTitle'
import { GiTooltipInformation } from 'features/gi/shared/components/giTooltipInformation/giTooltipInformation'
import { GaugeLabel } from 'features/gi/ecosystem/shared/components/gaugeLabel/gaugeLabel'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import Style from './gaugeSection.module.scss'


export const GaugeSection = ({ title, data, loadingData }) => {
  const {
    selectedDapp,
    selectedDappsToCompare
  } = useVerifiedGiDappsFiltersParams()
  const isMobile = useMobile()

  return (
    <div className={Style.container}>
      {!loadingData && data && ( 
        <>
          {data?.cumulative?.tg_growth_index?.currentValue && selectedDappsToCompare ? (
            <div className={Style.comparisonGaugeContainer}>
              <div className={Style.gaugeContainer}>
                <CustomGauge
                  darkMode
                  value={data?.dApp?.tg_growth_index?.currentValue?.toFixed(0) || 0}
                  level={handleGrowthIndexLevel(data?.dApp?.tg_growth_index?.currentValue || 0)}
                  thickness="small"
                />
                <GaugeLabel
                  darkMode
                  icon={selectedDapp.dApp.icon}
                />
              </div>
              <div className={Style.gaugeContainer}>
                <CustomGauge
                  darkMode
                  value={
                    selectedDappsToCompare.length > 1 ?
                    data?.cumulative?.tg_growth_index?.currentValue?.toFixed(0) || 0
                    :
                    data?.cumulative?.tg_growth_index?.currentValue?.toFixed(0) || 0}
                  level={
                    selectedDappsToCompare.length > 1 ?
                    handleGrowthIndexLevel(data.cumulative?.tg_growth_index?.currentValue?.toFixed(0)) || handleGrowthIndexLevel(0)
                    :
                    handleGrowthIndexLevel(data.cumulative?.tg_growth_index?.currentValue?.toFixed(0)) || handleGrowthIndexLevel(0)}
                  thickness="small"
                />
                <GaugeLabel
                  darkMode
                  icon={selectedDappsToCompare.length > 1 ? cumulativeIcon : selectedDappsToCompare[0].dApp.icon}
                />
              </div>
            </div>
          ) : (
            <CustomGauge
              darkMode
              value={data?.dApp?.tg_growth_index?.currentValue?.toFixed(0) || 0}
              level={handleGrowthIndexLevel(data?.dApp?.tg_growth_index?.currentValue || 0)}
            />
          )}
        </>
      )}
      {title && (
        <div className={Style.titleContainer}>
          <SectionTitle darkMode title={title} />
          <div
            className="relative"
            data-tooltip-id="tg-growth-index-information"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <GiTooltipInformation
                title="dApp Growth Index"
                information={informations.dapp.gi}
                metrics={informations.dapp.giListOfMetrics}
              />
            )}
            data-tooltip-place={isMobile ? "bottom" : "right-start"}
            data-tooltip-position-strategy="fixed"
            data-tooltip-offset={8}
          >
            <IconLib image="information2" pack="own" size="18" />
            <ReactTooltip
              id={'tg-growth-index-information'}
              light
            />
          </div>
        </div>
      )}
      {!loadingData && !data && (
        <div className="min-height relative">
          <Typography
            tag="p"
            color="white"
            text="N/A"
            size="xl"
            weight="regular"
            className="align-xy"
          />
        </div>
      )}
      {loadingData && <div className="min-height relative"><Loader /></div>}
      <GaugeLegend />
    </div>
  )
}

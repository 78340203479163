/***
 *
 *   BREADCRUMBS
 *   Navigation trail for nested pages.
 *
 *   PROPS
 *   items: array of objects containing keys: name and url
 *
 **********/

import React from 'react'
import { NavLink } from 'react-router-dom'
import Style from './breadcrumbs.module.scss'

export const Breadcrumbs = ( props ) => {

  return (
    <ul className={Style.breadcrumbs}>
        {props.items?.map((item, index) => {
          return (
            <li
             key={`${item.name}${index}`}>
              <NavLink
                to={item.url}
                className={({ isActive }) =>
                  isActive ? `${Style.activeLink} ${Style.link}` : Style.link
                }
                end
              >
                {item.name}
              </NavLink>
            </li>
          )
        })}
    </ul>
  )
}

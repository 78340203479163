import React from 'react'
import {
  Button
} from 'components/lib'
import logoTg from 'assets/images/logo-tokenguard-40x40.svg'
import chartCover from 'assets/images/cover-chart.svg'
import Style from './coverChartSection.module.scss'

export const CoverChartSection = () => (
  <>
    <img src={chartCover} className={Style.bgChartImage} alt="" />
    <div className="min-height relative">
      <div className={Style.contentContainer}>
        <img src={logoTg} alt="tokenguard logo" className={Style.logo} />
        <p className={Style.mainText}>This chart is currently locked</p>
        <p className={Style.additionalText}>Contact us to see all metrics</p>
        <Button small goto={'/contact'} text="Contact" />
      </div>
    </div>
  </>
)

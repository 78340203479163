/***
 *
 *   DAPP SANKEY SECTION
 *
 **********/

import React from 'react'

import user from 'assets/icons/user-gray.svg'
import {
  CustomSankey,
  Headline,
  useMobile
} from 'components/lib'

import { useSankeyData } from 'features/gi/dapp/giDappBasicDashboard/hooks/useSankeyData'
import { convertToSankeyFormat } from 'features/gi/dapp/giDappBasicDashboard/utils/helpers'
import Style from './dappSankeySection.module.scss'

export const DappSankeySection = ({
  dappSlug,
  blockchains
}) => {
  const isMobile = useMobile()
  const { sankeyData, _isLoadingSankeyData } = useSankeyData(dappSlug, blockchains)
  const suffix = ' '
  
  if (!sankeyData) {
    return null
  }

  return (
    <div className={Style.sankeySection}>
      <div className={Style.titleContainer}>
        <Headline
          logo={user}
          title="Movement Of Users"
        />
      </div>
      {sankeyData?.length > 0 ? (
        <CustomSankey
          data={convertToSankeyFormat(sankeyData, suffix, isMobile)}
        />
      ) : null}
      {sankeyData?.length === 0 ? (
        <div className="relative min-height">
          <p className="align-xy">There is currently no data available.</p>
        </div>
      ) : null}
    </div>
  )
}
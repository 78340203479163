/***
 * HOOK of creator tools nav items
 *
 **********/

export function useNavCreatorToolsItems() {

  const navCommunityItems = [
    {
      label: 'Dashboards',
      icon: 'dashboards',
      link: '/dashboards/public',
      related: ['dashboards/shared', '/dashboards/personal', '/dashboards'],
      disabled: false,
    },
    {
      label: 'Dashboard Builder',
      icon: 'plus',
      link: '/create',
      related: ['edit-dashboard/:dashboardId'],
      disabled: false,
    }
  ]

  return navCommunityItems
}

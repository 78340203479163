/***
 *
 *   useVerifiedFiltersParam hook
 * 
 **********/

import _React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useNavigate,
  useLocation,
} from 'components/lib'

import { verifyDateRangeParam } from 'features/gi/ecosystem/shared/utils/helpers'
import { useGiBlockchainsData } from 'features/gi/ecosystem/shared/hooks/useGiBlockchainsData'

export const useVerifiedFiltersParams = () => {
  const location = useLocation()
  const [verifiedBlockchainSegment, setVerifiedBlockchainSegment] = useState()
  const [verifiedCompareWithParam, setVerifiedCompareWithParam] = useState()
  const [verifiedParamsStr, setVerifiedParamsStr] = useState()
  const [searchParams, _setSearchParams] = useSearchParams()
  const [ , blockchainSegment ] = location.pathname.split('/')
  const verifiedDateRangeParam = verifyDateRangeParam(searchParams.get('period'))
  const compareWithParam = searchParams.get('compare with')
  const [selectedBlockchain, setSelectedBlockchain] = useState()
  const [selectedBlockchainsToCompare, setSelectedBlockchainsToCompare] = useState()
  const navigate = useNavigate()
  const { giBlockchainsData } = useGiBlockchainsData()
  if (!blockchainSegment) {
    navigate('/')
  }

  useEffect(() => {
    if (giBlockchainsData.length > 0) {
      if (!giBlockchainsData.some(obj => obj.blockchain.slug.toLowerCase() === blockchainSegment.toLowerCase())) {
        setVerifiedBlockchainSegment()
        navigate('/')
      }
      setVerifiedBlockchainSegment(blockchainSegment.toLowerCase())
      const filteredSelectedBlockchain = giBlockchainsData.filter(obj => obj.blockchain.slug.toLowerCase() === blockchainSegment.toLowerCase())[0]
      setSelectedBlockchain(filteredSelectedBlockchain)
      if (compareWithParam) {
        const compareWithParamArr = compareWithParam.split(',')
        let verifiedCompareWithParamArr = []
        let filteredBlockchainsToCompareArr = []
        compareWithParamArr.forEach(param => {
          const matchingObject = giBlockchainsData.find(obj => obj.blockchain.slug.toLowerCase() === param.toLowerCase() && obj.blockchain.name !== filteredSelectedBlockchain.blockchain.name)
          if (matchingObject) {
            verifiedCompareWithParamArr.push(param)
            filteredBlockchainsToCompareArr.push(matchingObject)
          }
        })
        if (verifiedCompareWithParamArr.length > 0) {
          const verifiedCompareWithParamStr = verifiedCompareWithParamArr.join(',').toLowerCase()
          setVerifiedCompareWithParam(verifiedCompareWithParamStr)
          setVerifiedParamsStr(`period=${verifiedDateRangeParam.toLowerCase()}&compareWith=${verifiedCompareWithParamStr}`)
          setSelectedBlockchainsToCompare(filteredBlockchainsToCompareArr)
        } else {
          setSelectedBlockchainsToCompare()
          setVerifiedCompareWithParam()
          setVerifiedParamsStr(`period=${verifiedDateRangeParam.toLowerCase()}`)
        }
      } else {
        setVerifiedCompareWithParam()
        setSelectedBlockchainsToCompare()
        setVerifiedParamsStr(`period=${verifiedDateRangeParam.toLowerCase()}`)
      }
    }
  
  }, [giBlockchainsData, blockchainSegment, compareWithParam, verifiedDateRangeParam])

  return {
    verifiedParamsStr: verifiedParamsStr,
    verifiedBlockchainSegment: verifiedBlockchainSegment,
    verifiedDateRangeParam: verifiedDateRangeParam,
    verifiedCompareWithParam: verifiedCompareWithParam,
    selectedBlockchain: selectedBlockchain,
    selectedBlockchainsToCompare: selectedBlockchainsToCompare
  }
}
/***
 *
 *   DAPP USERS PAGE
 *
 **********/

import React from 'react'

import {
  Animate,
  MainContentContainer,
} from 'components/lib'

import { DappUsers } from 'features/gi/dapp/dappUsers/dappUsers'

export function DappUsersPage() {

  return (
    <Animate type="pop">
      <MainContentContainer>
        <DappUsers />
      </MainContentContainer>
    </Animate>
  )
}
import React from 'react'

import { Icon } from 'components/lib'
import { paths } from 'utils/paths'

import Style from './ecosystemBanner.module.scss'

export const EcosystemBanner = () => (
  <a href={paths.giEcosystemOverview} className={Style.ecosystemBannerContainer}>
    <div className={Style.ecosystemBannerContent}>
      <Icon name="tgDashboards" width="16" height="16" />
      <p className={Style.ecosystemBannerTitle}>Ecosystem Ranking</p>
    </div>
  </a>
)

import React from 'react'

import {
  Animate,
  Icon,
  ButtonNew,
  Container,
  Headline,
  useNavigate,
  TabBar
} from 'components/lib'

import Style from './DashboardHeader.module.css'

export const DashboardHeader = ({ tabs, showCreateButton }) => {
  const navigate = useNavigate()

  return (
    <Container>
      <Animate type="pop">
        <div className={Style['content-container']}>
          <Headline
            title="Dashboards"
            subtitle="A List of Dashboards Created by You and the Community"
          />
          {showCreateButton && (
            <ButtonNew
              onClick={() => navigate('/create')}
              size="large"
              startIcon={<Icon name="plus" width="16" height="16" />}
            >
              Create Dashboard
            </ButtonNew>
          )}               
        </div>
        <TabBar underlyingLine tabs={tabs} />
      </Animate>
    </Container>
  )
}

import React from 'react'

import {
  Typography,
  Line,
  DropdownNew,
  ButtonNew,
  IconButton,
  CustomToggle,
  TextInputNew,
  NumberInput,
  CheckboxNew
} from 'components/lib'
import { Icon } from 'components/icon/Icon'

import Style from './MetricCard.module.css'

// type TMetricCardProps = {
//   metricType: string;
//   selectedUnit: 
//   setSelectedUnit,
//   isScBreakdown,
//   setIsScBreakdown,
//   metricItem,
//   setSelectedCalls,
//   setSelectedEvents,
//   selectedCalls,
//   selectedEvents
// }

export const MetricCard = ({
  metricType,
  selectedUnit,
  setSelectedUnit,
  isScBreakdown,
  setIsScBreakdown,
  metricItem,
  setSelectedCalls,
  setSelectedEvents,
  selectedCalls,
  selectedEvents
}) => {
  const removeSelectedCard = (item) => {
    if (item.type === 'call') {
      setSelectedCalls(prevSelectedCalls => {
        const hasCall = prevSelectedCalls?.some(obj => obj.name === item.name);
        if (hasCall) {
          return prevSelectedCalls.filter(obj => obj.name !== item.name);
        } else {
          return null
        }
      })
    } else {
      setSelectedEvents(prevSelectedEvents => {
        const hasEvent = prevSelectedEvents?.some(obj => obj.name === item.name);
        if (hasEvent) {
          return prevSelectedEvents.filter(obj => obj.name !== item.name);
        } else {
          return null
        }
      })
    }
  }

  const getCurrentOperator = (metricItem, itemName, index) => {
    if (metricItem.type === 'call') {
      const call = selectedCalls.find(el => el.name === metricItem.name)
      return call?.args?.[itemName]?.conditions?.[index]?.operator || '';
    } else {
      const event = selectedEvents.find(el => el.name === metricItem.name);
      return event?.args?.[itemName]?.conditions?.[index]?.operator || '';
    }
  }

  const verifyDisplayingAndCheckbox = (metricItem, item) => {
    if (metricItem.type === 'call') {
      const call = selectedCalls.find(el => el.name === metricItem.name);
      if (call?.args?.[item.name]?.conditions?.[0].operator) {
        return true
      }
      return false
    } else {
      const event = selectedEvents.find(el => el.name === metricItem.name);
      if (event?.args?.[item.name]?.conditions?.[0].operator) {
        return true
      }
      return false
    }
  }

  const verifyDoubleCondition = (metricItem, item) => {

    if (metricItem.type === 'call') {
      const call = selectedCalls.find(el => el.name === metricItem.name);
      if (call?.args?.[item.name]?.conditions?.[1]) {
        return true
      }
      return false
    } else {
      const event = selectedEvents.find(el => el.name === metricItem.name);
      if (event?.args?.[item.name]?.conditions?.[1]) {

        return true
      }

      return false
    }
  }

  const toggleDoubleCondition = (metricItem, item) => {
    const conditionTemplate = {
      value: 0,
      operator: '>'
    };
  
    const toggleCondition = (el) => {
      const existingConditions = el.args[item.name]?.conditions || [];
      
      let newConditions;
      if (existingConditions.length === 1) {
        newConditions = [...existingConditions, conditionTemplate];
      } else if (existingConditions.length === 2) {
        newConditions = existingConditions.slice(0, 1);
      } else {
        newConditions = existingConditions;
      }
  
      return {
        ...el,
        args: {
          ...el.args,
          [item.name]: {
            type: item.type,
            conditions: newConditions
          }
        }
      };
    };
  
    if (metricItem.type === 'call') {
      const modifiedCalls = selectedCalls.map(el => 
        metricItem.name === el.name ? toggleCondition(el) : el
      );
  
      setSelectedCalls(modifiedCalls);
    } else {
      const modifiedEvents = selectedEvents.map(el => 
        metricItem.name === el.name ? toggleCondition(el) : el
      );
  
      setSelectedEvents(modifiedEvents);
    }
  };

  const getCurrentBoolean = (metricItem, itemName) => {
    if (metricItem.type === 'call') {
      const call = selectedCalls.find(el => el.name === metricItem.name);
      return call?.args?.[itemName]?.value || '';
    } else {
      const event = selectedEvents.find(el => el.name === metricItem.name);
      return event?.args?.[itemName]?.value || '';
    }
  }
  
  const handleNumberOperatorFilter = (operator, metricItem, item, conditionIndex) => {
    const updateConditions = (args, item) => {
      const conditions = args[item.name]?.conditions || [];
      const updatedConditions = [...conditions];
      updatedConditions[conditionIndex] = {
        ...updatedConditions[conditionIndex],
        value: updatedConditions[conditionIndex]?.value || '5',
        operator: operator
      };
  
      return {
        ...args,
        [item.name]: {
          type: item.type,
          conditions: updatedConditions
        }
      }
    }
  
    if (metricItem.type === 'call') {
      const modifiedCalls = selectedCalls.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: updateConditions(el.args, item)
          };
        } else {
          return el;
        }
      });
      setSelectedCalls(modifiedCalls);
    } else {
      const modifiedEvents = selectedEvents.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: updateConditions(el.args, item)
          };
        } else {
          return el;
        }
      });
      setSelectedEvents(modifiedEvents);
    }
  }

  const handleNumberInputFilter = (e, metricItem, item, conditionIndex) => {
    const newValue = e.target.value;
  
    const updateConditions = (args, item) => {
      const conditions = args[item.name]?.conditions || [];
      const updatedConditions = [...conditions];
      updatedConditions[conditionIndex] = {
        ...updatedConditions[conditionIndex],
        value: newValue,
        operator: updatedConditions[conditionIndex]?.operator // retain existing operator
      };
  
      return {
        ...args,
        [item.name]: {
          type: item.type,
          conditions: updatedConditions
        }
      };
    };
  
    if (metricItem.type === 'call') {
      const modifiedCalls = selectedCalls.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: updateConditions(el.args, item)
          };
        } else {
          return el;
        }
      });
      setSelectedCalls(modifiedCalls);
    } else {
      const modifiedEvents = selectedEvents.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: updateConditions(el.args, item)
          };
        } else {
          return el;
        }
      });
      setSelectedEvents(modifiedEvents);
    }
  };

  const verifyActiveFilter = (item) => {
    if (metricItem.type === 'call') {
      const commonCall = selectedCalls?.filter(obj => obj.name === metricItem.name)
      if (commonCall[0]?.args?.[item.name]) {
        return true
      } else {
        return false
      }

    } else {
      const commonEvent = selectedEvents?.filter(obj => obj.name === metricItem.name)

      if (commonEvent && commonEvent[0]?.args?.[item.name]) {
        return true
      } else {
        return false
      }
    }
  }

  const handleBooleanFilter = (value, metricItem, item) => {
    if (metricItem.type === 'call') {
      const modifiedCalls = selectedCalls.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: {
              ...el.args,
              [item.name]: {
                value: value,
                type: item.type
              }
            }
          };
        } else {
          return el
        }
      });

      setSelectedCalls(modifiedCalls);
    } else {
      const modifiedEvents = selectedEvents.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: {
              ...el.args,
              [item.name]: {
                value: value,
                type: item.type
              }
            }
          };
        } else {
          return el
        }
      });

      setSelectedEvents(modifiedEvents);
    }
  }

  const handleStringFilter = (e: React.ChangeEvent<HTMLInputElement>, metricItem, item) => {
    if (metricItem.type === 'call') {
      const modifiedCalls = selectedCalls.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: {
              ...el.args,
              [item.name]: {
                value: e.target.value,
                type: item.type
              }
            }
          }
        } else {
          return el
        }
      })

      setSelectedCalls(modifiedCalls);
    } else {
      const modifiedEvents = selectedEvents.map(el => {
        if (metricItem.name === el.name) {
          return {
            ...el,
            args: {
              ...el.args,
              [item.name]: {
                value: e.target.value,
                type: item.type
              }
            }
          }
        } else {
          return el
        }
      });

      setSelectedEvents(modifiedEvents)
    }
  }

  const handleMetricFilters = (metricItem, item) => {
    let modifiedSelectedMetric = []
    if (metricItem.type === 'call') {
        modifiedSelectedMetric = selectedCalls
    } else {
        modifiedSelectedMetric = selectedEvents
    }
    const modifiedCalls = modifiedSelectedMetric.map(el => {
        if (metricItem.name === el.name) {
            let args = el.args || {};

            if (args[item.name]) {
                const {
                    [item.name]: _,
                    ...restArgs
                } = args
                return {
                    ...el,
                    args: restArgs
                };
            } else {
                return {
                    ...el,
                    args: {
                        ...args,
                        [item.name]: item.type === 'integer' ? {
                            conditions: [{ value: 0, operator: '>' }],
                            type: item.type
                        } : {
                            value: '',
                            type: item.type
                        }
                    }
                };
            }
        } else {
            return el
        }
    })
    if (metricItem.type === 'call') {
        setSelectedCalls(modifiedCalls)
    } else {
        setSelectedEvents(modifiedCalls)
    }
}

  if (metricType === 'Unit') {
    return (
      <div className={Style['metric-card']}>
        <div className={Style['metric-card-info']}>
          <Icon name="a" width="16" height="16" />
          <div className={Style['metric-card-name']}>
            <Icon name={selectedUnit.icon} width="16" height="16" />
            <Typography
              size="s"
              text={selectedUnit.name}
              weight="semi-bold"
              tag="h4"
              color="gray900"
            />
          </div>
          <IconButton
            onClick={() => setSelectedUnit(undefined)}
            icon={<Icon name="exitSmall" width="12" height="12" color="gray900" />}
          />
        </div>
        <div className={Style['metric-card-settings']}>
          <CustomToggle
            defaultChecked={isScBreakdown}
            onChange={() => setIsScBreakdown(!isScBreakdown)}
            icons={false}
            color="white"
          />
          <Typography
            size="s"
            text="Smart Contract Breakdown"
            weight="medium"
            tag="p"
            color="gray900"
          />
        </div>
      </div>
    )
  }

  return (
    <div className={`${Style['metric-card']} ${Style['metric-b-bg']}`}>
      <div className={Style['metric-card-info']}>
        <Icon name="b" width="16" height="16" />
        <div className={Style['metric-card-name']}>
          <Typography
            size="s"
            text={`${metricType} > ${metricItem.name}`}
            weight="semi-bold"
            tag="p"
            color="gray900"
          />
        </div>
        <IconButton
          onClick={() => removeSelectedCard(metricItem)}
          icon={<Icon name="exitSmall" width="12" height="12" color="gray900" />}
        />
      </div>
      {metricItem?.args && Object.entries(metricItem.args).map(([key, item]) => {
        if (item.type === 'string') {
          return (
            <div key={key} className={Style['filter-row']}>
              <Typography
                size="s"
                text={`${key} is`}
                weight="semi-bold"
                tag="p"
                color="gray900"
              />
              <TextInputNew
                name={key}
                change={(e) => handleStringFilter(e, metricItem, { name: key, type: item.type })}
                size="xxsmall"
                placeholder="Enter your text"
                value={item.value}
                className="fullWidth"
              />
            </div>
          )
        }

        if (item.type === 'boolean') {
          return (
            <div key={key} className={Style['filter-row']}>
              <button
                className={getCurrentBoolean(metricItem, key) === true ? `${Style['metric-boolean-operator']} ${Style['active-operator']}` : `${Style['metric-boolean-operator']}`}
                onClick={() => handleBooleanFilter(true, metricItem, { name: key, type: item.type, operator: true })}
              >
                true
              </button>
              <button
                className={getCurrentBoolean(metricItem, key) !== true ? `${Style['metric-boolean-operator']} ${Style['active-operator']}` : `${Style['metric-boolean-operator']}`}
                onClick={() => handleBooleanFilter(false, metricItem, { name: key, type: item.type, operator: false })}
              >
                false
              </button>
            </div>
          )
        }

        if (item.type === 'integer' || item.type === 'float') {
          return (
            <div key={key} className={Style['integer-filter-container']}>
                <div key={key} className={Style['integer-filter-row']}>
                <Typography
                  size="m"
                  text={`${key} is`}
                  weight="regular"
                  tag="p"
                  color="gray900"
                />
                <button
                  onClick={() => handleNumberOperatorFilter('>', metricItem, { name: key, type: item.type, operator: '>' }, 0)}
                  className={getCurrentOperator(metricItem, key, 0) === '>' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                >
                  {`>`}
                </button>
                <button
                  onClick={() => handleNumberOperatorFilter('=', metricItem, { name: key, type: item.type, operator: '=' }, 0)}
                  className={getCurrentOperator(metricItem, key, 0) === '=' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                >
                  =
                </button>
                <button
                  onClick={() => handleNumberOperatorFilter('<', metricItem, { name: key, type: item.type, operator: '<' }, 0)}
                  className={getCurrentOperator(metricItem, key, 0) === '<' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                >
                  {`<`}
                </button>
              </div>
              <div className={Style['integer-filter-row']}>
                <Typography
                  size="m"
                  text="than"
                  weight="regular"
                  tag="p"
                  color="gray900"
                />
                <NumberInput
                  name={key}
                  change={(e) => handleNumberInputFilter(e, metricItem, { name: key, type: item.type }, 0)}
                  size="xxsmall"
                  placeholder="Enter your value"
                  value={metricItem?.args?.[key]?.conditions?.[0]?.value || 0}
                />
              </div>
              <div className={Style['integer-filter-row']}>
                {verifyDisplayingAndCheckbox(metricItem, { name: key, type: item.type })&& (
                  <CheckboxNew
                    value={verifyDoubleCondition(metricItem, { name: key, type: item.type })}
                    onChange={() => toggleDoubleCondition(metricItem, { name: key, type: item.type })}
                    label="AND"
                  />
                )}
                {verifyDoubleCondition(metricItem, { name: key, type: item.type }) && (
                  <>
                    <button
                      onClick={() => handleNumberOperatorFilter('>', metricItem, { name: key, type: item.type, operator: '>' }, 1)}
                      className={getCurrentOperator(metricItem, key, 1) === '>' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                    >
                      {`>`}
                    </button>
                    <button
                      onClick={() => handleNumberOperatorFilter('=', metricItem, { name: key, type: item.type, operator: '=' }, 1)}
                      className={getCurrentOperator(metricItem, key, 1) === '=' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                    >
                      =
                    </button>
                    <button
                      onClick={() => handleNumberOperatorFilter('<', metricItem, { name: key, type: item.type, operator: '<' }, 1)}
                      className={getCurrentOperator(metricItem, key, 1) === '<' ? `${Style['metric-math-operator']} ${Style['active-operator']}` : `${Style['metric-math-operator']}`}
                    >
                      {`<`}
                    </button>
                  </>
                )}
              </div>
              {verifyDoubleCondition(metricItem, { name: key, type: item.type }) && (
                <div className={Style['integer-filter-row']}>
                  <Typography
                    size="m"
                    text="than"
                    weight="regular"
                    tag="p"
                    color="gray900"
                  />
                  <NumberInput
                    name={key}
                    change={(e) => handleNumberInputFilter(e, metricItem, { name: key, type: item.type }, 1)}
                    size="xxsmall"
                    placeholder="Enter your value"
                    value={metricItem?.args?.[key]?.conditions?.[1]?.value || 0}
                  />
                </div>
              )}
            </div>
          )
        }

        return null
      })}
      {metricItem?.args && Object.keys(metricItem.args).length > 0 ? <Line color="gray100" className="mt8 mb8" /> : null}
      <div className={Style['metric-card-settings']}>
        {metricItem?.filterOptions?.length > 0 ? (
          <DropdownNew
            title="Filter by"
            options={metricItem.filterOptions.map(item => ({
              label: item.name,
              value: item.name,
              type: verifyActiveFilter(item) ? 'selected' : null,
              action: () => handleMetricFilters(metricItem, item)
            }))}
            position="bottom"
            size="medium"
          >
            <ButtonNew
              onClick={() => console.log(`/filter-by/${metricItem.name}`)}
              size="xxsmall"
              color="white"
            >
              + Filter By
            </ButtonNew>
          </DropdownNew>
        ): null}
      </div>
    </div>
  )
}
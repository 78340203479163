import React, {
  useState,
  useEffect,
} from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import {
  ReactSelectWithIcon,
  ReactSelectMultiWithIcon,
  // CustomSelectNew,
  // CustomSelect
} from 'components/lib'
import { objectToQueryString } from 'utils/helpers'
import { paths } from 'utils/paths'

import { useGiBlockchainsData } from 'features/gi/ecosystem/shared/hooks/useGiBlockchainsData'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import Style from './filtersSection.module.scss'

export const FiltersSection = () => {
  const navigate = useNavigate()
  const [_searchParams, setSearchParams] = useSearchParams()
  const {
    selectedBlockchain,
    selectedBlockchainsToCompare,
    verifiedBlockchainSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam
  } = useVerifiedFiltersParams()
  const { giBlockchainsData } = useGiBlockchainsData()
  const [isFiltersReadyToDisplay, setIsFiltersReadyToDisplay] = useState(false)
  const location = useLocation()

  const handleBlockchainFilter = (option) => {
    const queryParams = {
      'period': verifiedDateRangeParam,
    }
    if (verifiedCompareWithParam) {
      const blockchainToCompareArr = verifiedCompareWithParam.split(',')
      blockchainToCompareArr.filter(item => item === option.value)
      let testStr = verifiedCompareWithParam
      if (blockchainToCompareArr.includes(option.value)) {
        const test = blockchainToCompareArr.filter(item => item !== option.value)
        testStr = test.join(',')
      }
      queryParams['compare with'] = testStr
    }
    const queryString = objectToQueryString(queryParams)
    
    if (location.pathname.includes('breakdown')) {
      navigate(`/${option.value}/${paths.giEcosystem}/breakdown?${queryString}`)
    } else {
      navigate(`/${option.value}/${paths.giEcosystem}?${queryString}`)
    }
  }

  const handleCompareToFilter = (option) => {
    const queryParams = {
      'period': verifiedDateRangeParam,
    }
  
    if (option.length > 0) {
      queryParams['compare with'] = option.map(obj => obj.value).join(',')
    }
  
    const queryString = objectToQueryString(queryParams)
    setSearchParams(queryString)
  }

  const handleDateFilter = (option) => {
    const queryParams = {
      'period': option.value,
    }
    if (verifiedCompareWithParam) {
      queryParams['compare with'] = verifiedCompareWithParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const blockchainsOptions = giBlockchainsData.filter(item => item.blockchain.name !== selectedBlockchain?.blockchain?.name).map(item => {
    return (
      {
        label: item.blockchain.name,
        value: item.blockchain.slug,
        icon: item.blockchain.logo,
        network: item.blockchain?.network || 'Mainnet',
        name: item.blockchain.name,
        id: item.blockchain.id,
      }
    )
  })

  const dateOptions = [
    {label: 'Last Month', value: 'last month'},
    {label: 'Last 3 Months', value: '3 months'},
    {label: 'Last 6 Months', value: '6 months'},
    {label: 'Last Year', value: 'last year'},
    {label: 'All Time', value: 'all time'},
  ]

  useEffect(() => {
    if (verifiedBlockchainSegment) {
      setIsFiltersReadyToDisplay(true) 
    }

  }, [
    verifiedBlockchainSegment,
    verifiedDateRangeParam,
    verifiedCompareWithParam,
    selectedBlockchainsToCompare,
  ])


  return (
    <>
      {isFiltersReadyToDisplay && (
        <section className={Style.section}>
          <ReactSelectWithIcon
            label="Blockchain"
            isSearchable
            isClearable
            classNamePrefix="react-select-with-icon"
            name="blockchain"
            defaultValue={{
              value: verifiedBlockchainSegment,
              label: selectedBlockchain.blockchain.name,
              icon: selectedBlockchain.blockchain.logo
            }}
            options={blockchainsOptions}
            change={handleBlockchainFilter}
            size="small"
          />
          <ReactSelectMultiWithIcon
            label="Compare With"
            isSearchable
            isClearable
            classNamePrefix="react-select-with-icon"
            name="compare"
            placeholder="Add Comparison"
            defaultValue={
              selectedBlockchainsToCompare ? 
              selectedBlockchainsToCompare.map(item => ({
                label: item.blockchain.name,
                value: item.blockchain.slug
              })) 
              : null}
            options={blockchainsOptions}
            change={handleCompareToFilter}
            size="small"
          />
          <ReactSelectWithIcon
            label="Date Range"
            isSearchable={false}
            classNamePrefix="react-select-with-icon"
            name="date"
            defaultValue={{
              value: verifiedDateRangeParam ?? '',
              label: dateOptions.filter(option => option.value === verifiedDateRangeParam)[0].label,
            }}
            options={dateOptions}
            change={handleDateFilter}
            size="small"
          />
          {/* <CustomSelectNew
            label="Blockchain"
            id="Blockchain"
            name="Blockchain"
            withArrow
            options={blockchainsOptions}
            change={handleBlockchainFilter}
            placeholder="Select blockchain"
            size="large"
            defaultValue={{
              value: verifiedBlockchainSegment,
              label: selectedBlockchain.blockchain.name,
              icon: selectedBlockchain.blockchain.logo
            }}
          />
          <CustomSelectNew
            label="Compare With"
            isSearchable
            isMulti
            isClearable
            // classNamePrefix="react-select-with-icon"
            name="compare"
            // placeholder="Add Comparison"
            defaultValue={
              selectedBlockchainsToCompare ? 
              selectedBlockchainsToCompare.map(item => ({
                label: item.blockchain.name,
                value: item.blockchain.slug
              })) 
              : null}
            options={blockchainsOptions}
            change={handleCompareToFilter}
            size="large"
          />
          <CustomSelectNew
            label="Date Range"
            name="date"
            withArrow
            defaultValue={{
              value: verifiedDateRangeParam ?? '',
              label: dateOptions.filter(option => option.value === verifiedDateRangeParam)[0].label,
            }}
            options={dateOptions}
            change={handleDateFilter}
            size="large"
          /> */}
        </section>
      )}
    </>
  )
}

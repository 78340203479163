/***
 *
 *   SEARCH
 *   Search input field
 *
 *   PROPS
 *   throttle: throttle the callback execution in ms
 *   action: function executed on change and submit
 *
 **********/

import React, { useState } from 'react'
import { ClassHelper } from 'components/lib'

import Style from './searchInput.module.scss'

export function SearchInput(props) {
  const [value, setValue] = useState(props.value || '')
  // const [typing, setTyping] = useState(false)

  const searchStyle = ClassHelper(Style, {
    withIcon: props.icon,
    [props.size]: props.size
  })

  // useEffect(() => {
  //   // throttle typing
  //   if (props.throttle && !typing) {
  //     const onKeyPress = () => {
  //       setTyping(true)
  //       setTimeout(() => {
  //         setTyping(false)
  //       }, props.throttle)
  //     }

  //     window.addEventListener('keydown', onKeyPress)
  //     return () => window.removeEventListener('keydown', onKeyPress)
  //   }
  // }, [props.throttle, typing])

  // useEffect(() => {
  //   // callback when typing throttle done
  //   if (props.throttle && !typing) props.action(value)
  // }, [props, typing, value])

  return (
    <div className={Style.searchInputContainer}>
      {props.icon}
      <input
        type="text"
        className={searchStyle}
        placeholder={props.placeholder || 'Search'}
        value={value}
        // onChange={(e) => {
        //   setValue(e.target.value)
        //   !props.throttle && props.action(e.target.value)
        // }}
        onChange={(e) => {
          setValue(e.target.value)
          props.action(e.target.value)
        }}
      />
    </div>
  )
}

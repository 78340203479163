import React from 'react'

import { InsightSingleValue } from 'features/gi/dapp/dappUsers/components/insightSingleValue'
import Style from './dappUsersBasicValues.module.scss'

export const DappUsersBasicValues = ({
  metrics,
}) => {

  return (
    <section className={Style.container}>
      {metrics?.length > 0 ? metrics.map(item => {
        return (
          <InsightSingleValue
            key={item.metric_name}
            title={item.metric_name}
            value={item.metric_value}
            prefix={item.metric_name === 'Deposits' && '$'}
          />
        )
      }) : null}
    </section>
  )
}

import React from 'react'
import { Article } from 'components/lib'

export function TrialExpired() {
  return (
    <Article>
      <h2>Your free trial has expired</h2>
    </Article>
  )
}

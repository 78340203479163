import React from 'react'
import { Helmet } from 'react-helmet-async'

export const Seo = ({
  title,
  description,
  imageUrl,
  name,
  type,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      <meta
        name="og:title"
        content={title}
      />
      <meta
        name="og:description"
        content={description}
      />
      <meta
        name="og:image"
        content={imageUrl}
      />
      <meta
        property="og:type"
        content={type}
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      {name && (
        <meta
          name="twitter:creator"
          content={name}
        />
      )}
      {type && (
        <meta
          name="twitter:card"
          content={type}
        />
      )}
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />
    </Helmet>
  )
}

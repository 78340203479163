import React, { useState } from 'react'
import { Icon, Link, Button, Container, Line, CheckList } from 'components/lib'
import Cookies from 'js-cookie'
import Style from './cookies.module.scss'

export const CookiesWidget = ({ setIsWidgetOpen }) => {
  const [cookieData, setCookieData] = useState({
    necessary: true,
    analytical: true,
    marketing: true,
  });

  const handleSubmitCookiesSettings = () => {
    Cookies.set('cookieConsent', JSON.stringify(cookieData), { expires: 365 });
    setIsWidgetOpen(false);
  };

  const handleExitCookiesSettings = () => {
    Cookies.set('cookieConsent', JSON.stringify({
      necessary: true,
      analytical: false,
      marketing: false,
    }), { expires: 365 });
    setIsWidgetOpen(false);
  };

  const toggleCookie = (cookieType) => {
    setCookieData((prevData) => ({
      ...prevData,
      [cookieType]: !prevData[cookieType],
    }));
  };

  const items = [
    {
      name: <p><span>Necessary cookies</span> help with the basic functionality of our website</p>,
      checked: true,
      disabled: true,
    },
    {
      name: <p><span>Analytical cookies</span> make it possible to gather statistics about the use and traffic on our website, so we can improve it</p>,
      onClick: () => toggleCookie('analytical'),
      checked: cookieData.analytical,
    },
    {
      name: <p><span>Marketing cookies</span> allow us to show you more relevant social media content and advertisements on our website</p>,
      onClick: () => toggleCookie('marketing'),
      checked: cookieData.marketing,
    },
  ]

  return (
    <div className={Style.widget}>
      <div className={Style.header}>
        <h3 className={Style.title}>We value your privacy</h3>
        <button onClick={() => handleExitCookiesSettings()}>
          <Icon name="exit" color="gray400" width="16" height="16" />
        </button>
      </div>
      <p className={Style.text}>
        We use cookies to enhance your experience and analyse our website traffic.
      </p>
      <p className={Style.text}>
        You can set your preferences by selecting the options below.
      </p>
      <p className={Style.text}>
        <Link
          url="https://tokenguard.io/privacy-policy"
          underline
          text="Learn more about how we use cookies on our website"
        />
      </p>
      <Line smallMarginTop smallMarginBottom />
      <CheckList
        className={Style.checklist}
        hideCross
        interactive
        items={items}
      />
      <Container flex marginTop16 noPadding>
        <Button
          small
          fullWidth
          text="Accept selected"
          action={() => handleSubmitCookiesSettings()}
        />
      </Container>
    </div>
  )
}

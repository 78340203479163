import React from 'react'

import news from 'features/gi/shared/assets/images/news-txt.png'
import Style from './newsBanner.module.scss'

export const NewsBanner = () => (
  <a href="https://tokenguard.io/blog" className={Style.newsBannerContainer}>
    <div className={Style.newsBannerContent}>
      <img src={news} alt="" />
    </div>
  </a>
)


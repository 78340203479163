import axios from 'axios'

import { endpoint } from 'utils/paths'
import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchGiDappUserInsightTimeline = async (verifiedAddress: string, dappSlug: string, chains: Array<string>) => {

  const response = await axios.get(
    `${serverURL}/${endpoint.apiGiDappUserInsightTimeline}`,
    {
      params: {
        userAddress: verifiedAddress,
        dapp: dappSlug,
        chains: chains
      }
    }
  )

  return response.data
}

export const fetchOtherDappsUsedData = async (dappSlug: string) => {
  const response = await axios.get(
    `${serverURL}/api/dapp-filters/other-dapps?dappSlug=${dappSlug}`,
  )

  return response.data
}
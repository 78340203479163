import skull from 'features/gi/shared/assets/icons/skull.svg'
import rocket from 'features/gi/shared/assets/icons/rocket.svg'
import fire from 'features/gi/shared/assets/icons/fire.svg'
import neutralFace from 'features/gi/shared/assets/icons/neutral-face.svg'
import angryFace from 'features/gi/shared/assets/icons/angry-face.svg'

export const items = [
  { scope: '0-20', icon: skull, level: "low", info: 'Whoops! Looks like there’s no growth at all' },
  { scope: '20-40', icon: angryFace, level: "low-mid", info: 'Looks like your chain isn’t growing! Analyse what might be the cause' },
  { scope: '40-60', icon: neutralFace, level: "middle", info: 'Watch out! Below 50 points means your growth index is falling' },
  { scope: '60-80', icon: fire, level: "mid-hight", info: 'Your growth is looking good! The 60-80 region is a great score to aim for' },
  { scope: '80-100', icon: rocket, level: "hight", info: 'Thats an amazing score! Your growth is going through the roof' }
]
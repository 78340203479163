/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from 'react'
import { AuthNav, Page, Header, Footer } from 'components/lib'
import Style from './auth.module.scss'

export function AuthLayout(props) {
  return (
    <Page>
      <Header>
      </Header>
      <main className={Style.auth}>{<props.children {...props.data} />}</main>
    </Page>
  )
}

/***
 *
 *   useGiDappsData hook
 *   for getting growth index dapps data and set in to context 
 * 
 **********/

import {
  useState,
  useEffect,
  useContext,
} from 'react'

import {
  ViewContext,
  GiDappsDataContext,
} from 'components/lib'
import { fetchGiDappsData } from 'utils/fetches/gi'

export const useGiDappsData = () => {
  const [giDappsData, setGiDappsData] = useState([])
  const [isGiDappsDataLoading, setIsGiDappsDataLoading] = useState(false)
  const viewContext = useContext(ViewContext)
  const { giDappsDataContext, setGiDappsDataContext } = useContext(GiDappsDataContext)

  useEffect(() => {
    if (giDappsDataContext.length > 0) {
      setGiDappsData(giDappsDataContext)
    } else {
      const getGiBlockchainsData = async () => {
        try {
          setIsGiDappsDataLoading(true)
          const fetchedGrowthIndexChainData = await fetchGiDappsData()
          setGiDappsData(fetchedGrowthIndexChainData.data)
          setGiDappsDataContext(fetchedGrowthIndexChainData.data)
          setIsGiDappsDataLoading(false)
        } catch (err) {
          setIsGiDappsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      getGiBlockchainsData()
    }
  }, [])

  return { giDappsData, isGiDappsDataLoading }
}
